@import "../../../../styles/styles.scss";

.appBar2 {
  height: 60px;
  background-color: var(--primary-2) !important;
}

.search {
  flex: 2;
  position: relative;
  border-radius: 5px;
  margin: 0 5vw;
  background-color: var(--b-w-6);

  font-family: OpenSans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--b-w-1);

  &:hover {
    background-color: whitesmoke;
  }
}

.searchIcon {
  padding: 0 16px;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  color: var(--b-w-3);
}

.inputRoot {
  color: inherit;
  width: 100%;
}

.inputInput {
  padding: 8px 8px 8px 48px !important;
  // vertical padding + font size from searchIcon
  padding-left: calc(1em + 36px) !important;
}

.linkContainer {
  display: flex;
  flex: 4;
  flex-direction: row;
  padding: 0 60px;

  & a {
    margin-right: 2vw;
    padding-top: 17px;
    padding-bottom: 17px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--b-w-6);
  }
}

.link {
  color: white;
  text-decoration: none;
}

.linkActive {
  font-weight: bold;
  border-bottom: 4px solid var(--b-w-6);
}

.profileName {
  padding-top: 17px;
  padding-bottom: 17px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--b-w-6);
}
