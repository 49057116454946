.homeAdmin {
  * {
    font-family: Poppins;
  }
  display: flex;
  flex: 1;
  justify-content: center;

  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to top, white 97%, white) !important;
  .companiesHeroContent {
    width: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    background: linear-gradient(to top, #048aa8 3%, #1d3557) !important;
    height: 300px;
  }
  .companiesContainer {
    display: flex;
    flex-direction: column;
    padding: 4vh 8vh;
  }
  // color

  .grayText {
    color: #777274;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    margin: 0;
  }
  .blackText {
    color: #000;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    margin: 0;
    display: flex;
    align-items: center;
    margin-top: 6px;
    .smallText {
      font-size: 16px;
      font-weight: 500;
      margin-right: 8px;
      margin-top: 6px;
    }
    .greenText {
      color: #11b78c;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: italic;
      margin-left: 8px;
    }
  }
  .marginTopbase {
    margin-top: 8px;
  }

  .companiesHero {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: 600px;
    height: calc(60vh - 80px);
    justify-content: center;

    .content {
      padding: 4vh;
      padding-left: 0;

      .contentTitle {
        font-size: 48px;
        font-weight: normal;
        font-style: italic;
        margin: 0;
        span {
          font-weight: 500;
          font-style: normal;
        }
      }
      .contentSubTitle {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: italic;
        margin: 0;
        text-align: center;
      }

      p {
        line-height: 200%;
        margin-bottom: 2rem;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        color: var(--b-w-5);
      }

      ul {
        padding: 0;
        list-style-position: inside;
        list-style-type: none;
        font-weight: 400;
      }

      li {
        padding-left: 1.3em;
      }

      li:before {
        content: "-"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        color: var(--success-dark);
      }

      .text-style-1 {
        font-weight: 500;
        font-style: italic;
      }
    }
  }
  .companiesContent {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #fafafa;
    .companiesContentData {
      width: 65vw;
      border-radius: 5px;
      margin-bottom: 3rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
  .white_button {
    font-family: Poppins;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--primary-1);
    padding: 0.5rem 1rem;
    border-color: white;
    width: 210px;
    border-radius: 5px;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -5px -5px 8px 0 var(--b-w-6);
    background-color: var(--b-w-6);
    border-width: 0;
    height: 39px;
  }
  .grayLine {
    width: 100%;
    height: 1px;
    margin: 1.2rem 8px 1rem 0;
    background-color: #ececec;
  }
  .bigLine {
    height: 2px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .listOfCompanies {
    margin-top: 1rem;
  }
  .contentPrincipalColumn {
    width: 65%;
  }

  .principalContentCompany {
    background-color: white;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -2px 1px 8px 0 var(--b-w-6);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
  .sideContent {
    width: 30%;
    .sideOpt {
      width: 100%;
      .redContent {
        width: calc(100% - 16px);
        border-radius: 5px;
        background-color: rgba(239, 71, 111, 0.2);
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        color: #c5274d;
        padding: 8px;
        cursor: pointer;
      }
      .infoContent {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .white_button {
        margin: 1rem 0;
      }
    }
  }

  .graphicHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .option {
      min-width: 120px;
      .selectElement {
        width: 130px;
        margin-top: 6px;
      }
    }
  }
  // COMPANY UNSELECTED
  .savings_title {
    color: #000;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
  }
  .savingsContent {
    width: 100%;
    padding: 2rem;
    .tabHeader {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      .tabItem {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
          width: 75px;
          height: 60px;
        }
        .companyName {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
        }
        .blackText {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;
          .smallText {
            font-size: 16px;
            font-weight: 500;
            margin-right: 8px;
            margin-top: 6px;
          }
          .greenText {
            color: #11b78c;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: italic;
            margin-left: 8px;
          }
        }
      }
    }
    .tableBodyHeader {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-top: 1rem;
      .tabItem {
        width: 32%;
        &:nth-child(2) {
          width: 17%;
        }
      }
    }
    .tableBody {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-top: 1rem;
      .tabItem {
        width: 32%;
        &:nth-child(2) {
          width: 17%;
        }
        .circleText {
          display: flex;
          align-items: center;
        }
        .blackText {
          color: #000;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          .circle {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 1rem;
          }
          .blueDarkColor {
            background-color: #1d3557;
          }
          .blueMediumColor {
            background-color: #048aa8;
          }
          .blueLightColor {
            background-color: #6cb6f5;
          }
          .pinkColor {
            background-color: #ef476f;
          }
          .orangeColor {
            background-color: #fa7e58;
          }
          .yellowColor {
            background-color: #f5a61d;
          }
          .greenColor {
            background-color: #15db93;
          }
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
  .totalComapniesTitle {
    width: 100%;
    color: #000;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    margin: 0;
  }
  .companiesHeader {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    .item {
      width: 18%;
      &:first-child {
        width: 40%;
      }
    }
    .grayDarkText {
      color: #777274;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      span {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
      }
      .greenText {
        color: #11b78c;
      }
    }
  }
  // white box
  .whiteShadowBox {
    padding: 16px;
    border-radius: 5px;
    box-shadow: 6px 6px 16px 0 rgba(166, 171, 189, 0.5);
    background-color: white;
    margin-top: 1rem;
    .bigSubtitle {
      margin: 0;
      font-size: 24px;
      font-weight: 300;
      font-stretch: normal;
      font-style: italic;
      color: #000;
    }
  }
}

// CANCEL MODAL
.modal-box {
  * {
    font-family: "Poppins";
  }
  .solicitudesTitle {
    margin-top: 1.5rem;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    color: #000000;
    opacity: 0.8;
    text-align: left;
    margin-bottom: 1rem;
  }
  .whiteBoxElementContent {
    border-radius: 10px;
    box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
      -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
    background-color: #ffffff;
    cursor: pointer;
    .inlineBoxElements {
      display: flex;
      padding: 16px;
      text-align: start;
      .boxElement {
        width: calc(95% / 2);
        &:last-child {
          width: 5%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .moreInfoIcon {
          font-size: 14px;
          color: #000000;
        }
      }
    }
  }
}
