.form__group {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 18px;

  .form__label {
    margin-top: 8px;
    font-family: Poppins;
    font-style: italic;
    font-weight: bold;
    font-size: 14px;
    line-height: normal;
    color: #4e4e4e;
  }

  .input-info {
    width: 95%;
    border-radius: 20px;
    margin-top: 8px;
    padding: 10px;
    border: solid 1px #e5e5e5;
    background-color: #fff;
    font-family: Poppins;
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #8d8d8d;
    mix-blend-mode: normal;
  }
}
.recovery_email {
  margin-bottom: 50px;
}
.recovery-btn {
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  padding: 10px 0 10px 0;
  margin-top: 1em;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1.14286px;
  text-transform: uppercase;
  color: #373737;
}

span.error_notification {
  color: red;
}
