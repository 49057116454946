.profile {
  * {
    font-family: Poppins;
  }
  display: flex;
  flex: 1;
  justify-content: center;

  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to top, white 97%, white) !important;
  .profileHeroContent {
    width: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    background: url("../../../assets/images/employee/profileHero.svg") no-repeat
        left center,
      linear-gradient(to top, #048aa8 3%, #1d3557) !important;
    height: 300px;
  }
  .profileContainer {
    display: flex;
    flex-direction: column;
    width: 65vw;
    padding: 4vh 8vh;

    .breadcrumbs {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding-right: 18px;
        padding-left: 18px;
        border-right: 2px solid;

        a {
          text-decoration: none;
          color: inherit;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .profileHero {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: 600px;
    height: calc(60vh - 80px);
    justify-content: space-between;

    .content {
      padding: 4vh;
      padding-left: 0;

      .contentTitle {
        font-size: 48px;
        font-weight: bold;
        font-style: italic;
      }

      p {
        line-height: 200%;
        margin-bottom: 2rem;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        color: var(--b-w-5);
      }

      ul {
        padding: 0;
        list-style-position: inside;
        list-style-type: none;
        font-weight: 400;
      }

      li {
        padding-left: 1.3em;
      }

      li:before {
        content: "-"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        color: var(--success-dark);
      }

      .text-style-1 {
        font-weight: 500;
        font-style: italic;
      }
    }
  }
  .profileContent {
    width: 100%;
    display: flex;
    justify-content: center;
    .profileContentData {
      width: 65vw;
      width: 65vw;
      background-color: white;
      box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
        -2px 1px 8px 0 var(--b-w-6);
      border-radius: 5px;
      margin-top: -5%;
      margin-bottom: 3rem;
    }
  }
  .navtabstable {
    display: flex;
    padding: 0 1rem;
    color: #1d3557;
    border-bottom: 1px solid #ececec;
    .navtab {
      padding: 1rem;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
    .navtabselected {
      border-bottom: 3px solid #1d3557;
    }
    .navtabsunelected {
      border-bottom: 1px solid transparent;
    }
  }
  .firstContent {
    padding: 1.5rem 4rem 0 4rem;
  }
  .profileTitle {
    font-size: 16px;
    font-weight: normal;
    color: #1d3557;
    margin: 0;
  }
  .profileInfoContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 1rem 0;
    .profileInfoElement {
      width: 25%;
      margin-bottom: 1rem;
      label {
        color: #777274;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
      }
      p {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        margin: 8px 0 0 0;
      }
    }
  }
  .start_content {
    justify-content: flex-start;
  }
  .grayLine {
    width: 301px;
    height: 1px;
    margin: 40px 8px 40px 0;
    background-color: #ececec;
  }
  .white_button {
    font-family: Poppins;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--primary-1);
    padding: 0.5rem 1rem;
    border-color: white;
    width: 236px;
    border-radius: 5px;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -5px -5px 8px 0 var(--b-w-6);
    background-color: var(--b-w-6);
    border-width: 0;
    height: 39px;
  }
  .goodPass {
    background-color: #11b78c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    color: white;
    font-size: 12px;
    width: 80px;
    border-radius: 5px;
    margin-top: 8px;
  }
  .notificationSwitch {
    display: flex;
    margin: 8px 0 8px 1rem;
  }
  .base_notification {
    margin-left: -3rem;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
  }
  .selectedNot {
    color: black;
  }
  .unselectedNot {
    color: #c4bfc1;
  }
  // Switch button
  .MuiSwitch-thumb {
    background-color: #fafafa;
    box-shadow: 0 0 8px 0 #a6abbd;
  }
  .MuiSwitch-track {
    background-color: #a6abbd;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #048aa8;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #048aa8;
  }

  .halfPart {
    width: 65%;
    .bigSize {
      width: 50%;
    }
  }
}
