.savingsStart {
  display: flex;
  flex: 1;
  justify-content: center;
  background: linear-gradient(0deg, #048aa8 3.46%, #1d3557 100%) !important;
  color: white;

  .savingsContainer {
    display: flex;
    flex-direction: column;
    width: 80vw;
    padding: 4vh 8vh;

    .breadcrumbs {
      display: flex;
      list-style-type: none;
      font-family: Poppins;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
      padding-left: 0;

      li {
        padding-right: 18px;
        padding-left: 18px;
        border-right: 2px solid;

        a {
          text-decoration: none;
          color: inherit;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .savingsHero {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 10vh;

    .savingHeroRight {
      width: 60%;
      img {
        width: 100%;
      }
    }

    .content {
      padding: 4vh;
      padding-left: 0;

      .contentTitle {
        font-family: Poppins;
        font-style: italic;
        font-weight: bold;
        font-size: 48px;
        line-height: 72px;
        color: #ffffff;
      }

      p {
        font-family: Open Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        margin-bottom: 1em;

        .text-style-1 {
          font-weight: bold;
          font-style: italic;
        }
      }

      button {
        padding: 0.5rem 1rem;
        border-radius: 5px;
        background-color: #fff;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #048aa8;
        border: none;
      }
    }

    .content-summary {
      padding: 4vh;
      padding-left: 0;
      width: 100%;

      .active-summary {
        margin: 24px 24px 30px 30px;
        background-color: var(--b-w-5);
        display: flex;
        border-left: 10px solid var(--b-w-1);
        border-radius: 10px;

        .summary {
          width: 100%;
          height: fit-content;
          padding: 24px 15px 32px 0;
          border-radius: 10px;
          //box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5), -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
          background-color: var(--b-w-6);
          color: var(--b-w-2);

          .form-message {
            margin: 0 0 16px;
            font-family: "Open Sans", sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.63;
            letter-spacing: normal;
            color: var(--primary-2);
          }

          .form-title {
            margin: 0 167px 34px 23px;
            font-family: "Open Sans", sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: italic;
            line-height: normal;
            letter-spacing: normal;
            color: #122745;
          }

          .form-row {
            min-height: 50px;
            border-bottom: 1px solid var(--b-w-3);
            display: flex;
            justify-content: space-around;

            &:last-child {
              border-bottom: none;
            }

            .icon {
              margin: 0px 0px 0px 30px;
            }

            .off-item {
              width: 40%;

              .item2 {
                margin: 24px 24px 30px 0px;
                max-width: 100%;
                display: flex;
                flex-direction: column;

                .title {
                  margin-top: 15px;
                  font-family: "Poppins", sans-serif;
                  font-size: 16px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: italic;
                  line-height: normal;
                  letter-spacing: normal;
                  color: var(--b-w-2);
                }
                .value {
                  font-weight: bold;
                }
              }
            }

            .pad-left {
              padding-left: 15%;
            }

            .item {
              margin: 24px 24px 30px 0px;
              max-width: 100%;
              display: flex;
              flex-direction: column;

              .value {
                font-weight: bold;

                .input-normal {
                  width: 100%;
                  margin: 8px 0px 0px 0px;
                  padding: 9px 9px 8px 10px;
                  border-radius: 5px;
                  border: solid 1px var(--success-dark);
                  background-color: var(--b-w-6);
                }

                .input-savings {
                  width: 200px;
                  margin: 8px 0px 0px 0px;
                  padding: 9px 9px 8px 10px;
                  border-radius: 5px;
                  border: none;
                  //   border: solid 1px #ECECEC !important;
                  background-color: var(--b-w-6);
                }

                .input-savings input:invalid {
                  background-color: #ffdddd;
                }

                .input-savings input:valid {
                  background-color: #ddffdd;
                }

                .input-info {
                  width: 90%;
                  margin: 8px 0px 0px 0px;
                  padding: 9px 9px 8px 10px;
                  border-radius: 5px;
                  border: solid 1px var(--b-w-4);
                  background-color: var(--b-w-6);
                }

                .input-normal::placeholder {
                  font-family: "Poppins", sans-serif;
                  font-size: 16px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: italic;
                  line-height: normal;
                  letter-spacing: normal;
                  color: var(--b-w-3);
                }
              }

              .title {
                margin-top: 15px;
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: italic;
                line-height: normal;
                letter-spacing: normal;
                color: var(--b-w-2);
              }

              .sub-title {
                margin-top: 20px;
                font-family: "Open Sans", sans-serif;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.63;
                letter-spacing: normal;
                color: var(--primary-2);
              }
            }
          }

          .comment {
            display: block;
            margin: 16px 2px 13px 0;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-weight: 500;
            font-stretch: normal;
            font-style: italic;
            line-height: normal;
            letter-spacing: normal;
            color: var(--b-w-2);
          }

          .border-option {
            margin-top: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid var(--b-w-3);
          }

          .total-gain {
            margin: 19px 2px 10px 0;
            font-family: "Poppins", sans-serif;
            font-size: 32px;
            font-weight: 300;
            font-stretch: normal;
            font-style: italic;
            line-height: normal;
            letter-spacing: normal;
            color: var(--b-w-1);
          }

          .dot {
            height: 16px;
            width: 16px;
            margin: 16px 16px 0px 1px;
            border-radius: 50%;
            display: inline-block;
          }

          .dot-black {
            background-color: var(--primary-2);
          }

          .dot-blue {
            background-color: var(--primary-1);
          }

          .dot-softblue {
            background-color: var(--links-light);
          }

          .dot-red {
            background-color: var(--danger-light);
          }

          .dot-orange {
            background-color: var(--warning-light);
          }

          .dot-yellow {
            background-color: var(--alert-light);
          }

          .dot-green {
            background-color: var(--success-light);
          }
        }
      }
    }

    .logo {
      min-width: 50%;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 4vh;
    }
  }
}

.startSavings {
  display: flex;
  justify-content: center;
  flex: 1;
  background: linear-gradient(0deg, #048aa8 3.46%, #1d3557 100%) !important;
  color: white;

  .savingsContainer {
    display: flex;
    flex-direction: column;
    width: 80vw;
    padding: 4vh 8vh;

    .breadcrumbs {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-family: Poppins;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;

      li {
        padding-right: 18px;
        padding-left: 18px;
        border-right: 2px solid;

        a {
          text-decoration: none;
          color: inherit;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .savingsHero {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .content {
      padding: 4vh;
      padding-left: 0;

      .contentTitle {
        margin: 0 102px 24px 1px;
        font-family: Poppins;
        font-style: italic;
        font-weight: 300;
        font-size: 32px;
        line-height: 48px;
        /* identical to box height */

        color: #ffffff;
      }

      p {
        display: flex;
        flex-direction: column;
        line-height: 200%;
        font-size: 18px;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        color: var(--b-w-6);

        .text-style-1 {
          font-weight: bold;
          font-style: italic;
          margin: 24px 102px 24px 1px;
        }

        .text-style-2 {
          margin: 24px 102px 24px 1px;
          font-family: Poppins;
          font-style: italic;
          font-weight: bold;
          font-size: 48px;
          line-height: 72px;
          /* identical to box height */

          color: #ffffff;
        }
      }
    }
  }
}

.startSavings-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  .navigation {
    display: flex;
    width: 70%;
    width: 80vw;
    button {
      margin-left: auto;
      margin-right: auto;
      border-radius: 5px;
      background-color: #fafafa;
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1px;
      border: none;
    }

    .button-back {
      width: 144px;
      height: 39px;
      margin: 40px 40px 24px 24px;
      padding: 8px 24px;
      border-radius: 5px;
      box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
        -5px -5px 8px 0 var(--b-w-6);
      background-color: var(--b-w-5);
      // color: var(--b-w-1);
      line-height: normal;
      letter-spacing: 1px;
      color: var(--b-w-2);
    }

    .button-next {
      width: 204px;
      height: 39px;
      margin: 40px 40px 24px 24px;
      padding: 8px 24px;
      border-radius: 5px;
      box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
        -5px -5px 8px 0 var(--b-w-6);
      background-color: var(--b-w-6);
      // color: var(--success-light);
      line-height: normal;
      letter-spacing: 1px;
      color: var(--success-dark);
    }

    .stepper-lane {
      margin-left: auto;
      margin-right: auto;
    }

    .MuiStepper-root {
      padding-top: 44px;
      padding-bottom: 0;
      background-color: unset;
    }
  }

  .form-savings {
    width: 100%;
    margin: 30px;

    .up-title {
      margin: 24px 85px 16px 45px;
      font-family: "Poppins", sans-serif;
      font-size: 24px;
      font-weight: 300;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      color: #122745;
    }

    .info-title {
      margin: 24px 85px 16px 45px;
      font-family: "Open Sans", sans-serif;
      font-size: 16px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: #122745;
    }

    .up-warning {
      margin: 16px 16px 22px 45px;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.17;
      letter-spacing: normal;
      color: var(--danger-dark);
    }

    .paso-3 {
      margin: 24px 24px 30px 30px;
      display: flex;

      .form-message2 {
        font-family: Poppins;
        font-style: italic;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        color: #1d3557;
      }

      .total-gain {
        margin: 19px 2px 10px 0;
        font-family: "Poppins", sans-serif;
        font-size: 32px;
        font-weight: 300;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: var(--b-w-1);
      }

      .dot {
        height: 16px;
        width: 16px;
        margin: 5px 10px 0px 0px;
        border-radius: 50%;
        display: inline-block;
      }

      .dot-black {
        background-color: var(--primary-2);
      }

      .dot-blue {
        background-color: var(--primary-1);
      }

      .dot-softblue {
        background-color: var(--links-light);
      }

      .dot-red {
        background-color: var(--danger-light);
      }

      .dot-orange {
        background-color: var(--warning-light);
      }

      .dot-yellow {
        background-color: var(--alert-light);
      }

      .dot-green {
        background-color: var(--success-light);
      }

      .chartAmount {
        .topCurrencySymbol {
          font-family: Poppins;
          font-style: italic;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;

          color: #1d3557;
        }
        .topAmount {
          font-family: Poppins;
          font-style: italic;
          font-weight: 500;
          font-size: 24px;
          line-height: 36px;
          /* identical to box height */

          color: #1d3557;
        }
        .bottomAmount {
          font-family: Poppins;
          font-style: italic;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;

          color: #777274;
        }
      }

      .form-left {
        width: 100%;

        height: fit-content;
        padding: 40px;
        border-radius: 10px;
        box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5);
        background-color: var(--b-w-6);
        border-left: 10px solid var(--success-light);
      }
      .form-row {
        border-bottom: 1px solid #ececec;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
        .off-item {
          width: 50%;
        }
        .item {
          margin: 24px 24px 30px 0px;
          max-width: 100%;
          display: flex;
          flex-direction: column;
        }
        .form-message {
          font-family: Open Sans;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 26px;
          color: #1d3557;
        }
        .title {
          margin-top: 15px;
          font-family: Poppins;
          font-style: italic;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #777274;

          padding-right: 10px;
        }
        .value-data {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-1);
          padding-top: 10px;
        }
        .input-info {
          width: 90%;
          margin: 8px 0px 0px 0px;
          padding: 9px 9px 8px 10px;
          border-radius: 5px;
          border: solid 1px var(--b-w-4);
          background-color: var(--b-w-6);
        }
      }
    }

    .paso-1 {
      margin: 24px 24px 30px 30px;
      display: flex;

      .currencySymbolSaving {
        font-size: 16px;
      }

      .form-left {
        width: 75%;
        height: fit-content;
        padding: 24px 15px 32px 0;
        border-radius: 10px;
        box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5);
        background-color: var(--b-w-6);

        .form-message {
          margin-left: 3em;
          font-family: Open Sans;
          font-style: italic;
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
          color: #122745;
        }

        .form-title {
          margin: 0 167px 34px 23px;
          font-family: "Open Sans", sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: #122745;
        }

        .form-row {
          border-bottom: 1px solid #ececec;
          display: flex;
          justify-content: space-around;
          align-items: flex-start;

          &:last-child {
            border-bottom: none;
          }

          .iconContainer {
            margin-top: auto;
            margin-bottom: auto;
          }

          .icon {
            color: #1d3557;
            width: 14px;
          }

          .off-item {
            width: 40%;
          }

          .pad-left {
            padding-left: 15%;
          }

          .item2 {
            margin: 24px 24px 30px 0px;
            max-width: 100%;
            display: flex;
            flex-direction: column;

            .title {
              margin-top: 15px;
              font-family: "Poppins", sans-serif;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: italic;
              line-height: normal;
              letter-spacing: normal;
              color: var(--b-w-2);
            }
            .value {
              font-weight: bold;
              .input-info {
                width: 90%;
                margin: 8px 0px 0px 0px;
                padding: 9px 9px 8px 10px;
                border-radius: 5px;
                border: solid 1px var(--b-w-4);
                background-color: var(--b-w-6);
              }
            }
            .value-data {
              font-family: "Poppins", sans-serif;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: italic;
              line-height: normal;
              letter-spacing: normal;
              color: var(--b-w-1);
              padding-top: 10px;
            }
            .input-percent {
              width: 60%;
              margin: 8px 0px 0px 0px;
              padding: 9px 9px 8px 10px;
              border-radius: 5px;
              border: solid 1px var(--b-w-4);
              background-color: var(--b-w-6);
            }
            .input-delete {
              width: 20%;
              margin: 8px 0px 0px 10px;
              padding: 9px 9px 8px 10px;
              border-radius: 5px;
              border: solid 1px var(--b-w-4);
              background-color: var(--b-w-6);

              .red-delete {
                color: var(--danger-dark);
              }
            }
          }

          .item {
            max-width: 100%;
            margin: 24px 24px 30px 0px;
            width: 200px;
            display: flex;
            flex-direction: column;

            .value {
              font-weight: bold;

              .input-normal {
                width: 100%;
                margin: 0px 0px 0px 0px;
                padding: 9px 9px 8px 10px;
                border-radius: 5px;
                border: solid 1px var(--b-w-4);
                background-color: var(--b-w-6);
              }

              .input-info {
                width: 90%;
                margin: 8px 0px 0px 0px;
                padding: 9px 9px 8px 10px;
                border-radius: 5px;
                border: solid 1px var(--b-w-4);
                background-color: var(--b-w-6);
              }

              .input-pack {
                display: inline-flex;
              }

              .input-percent {
                width: 60%;
                margin: 8px 0px 0px 0px;
                padding: 9px 9px 8px 10px;
                border-radius: 5px;
                border: solid 1px var(--b-w-4);
                background-color: var(--b-w-6);
              }

              .input-delete {
                width: 20%;
                margin: 8px 0px 0px 10px;
                padding: 9px 9px 8px 10px;
                border-radius: 5px;
                border: solid 1px var(--b-w-4);
                background-color: var(--b-w-6);

                .red-delete {
                  color: var(--danger-dark);
                }
              }

              .input-normal::placeholder {
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: italic;
                line-height: normal;
                letter-spacing: normal;
                color: var(--b-w-3);
              }
            }

            .title {
              margin-top: 15px;
              font-family: "Poppins", sans-serif;
              font-size: 12px;
              font-weight: 500;
              font-stretch: normal;
              font-style: italic;
              line-height: normal;
              letter-spacing: normal;
              color: var(--b-w-2);
              margin: auto;
              padding-left: 10px;
              padding-right: 10px;
            }

            .value-data {
              font-family: "Poppins", sans-serif;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: italic;
              line-height: normal;
              letter-spacing: normal;
              color: var(--b-w-1);
              padding-top: 10px;
            }

            .value-data-2 {
              padding-left: 10px;
              padding-right: 10px;
            }

            .value-info {
              font-family: "Poppins", sans-serif;
              font-size: 25px;
              font-weight: 300;
              font-stretch: normal;
              font-style: italic;
              line-height: normal;
              letter-spacing: normal;
              color: var(--b-w-1);
              padding-top: 6px;
              padding-left: 10px;
              padding-right: 10px;
            }

            .button-more {
              width: 344px;
              height: 39px;
              margin: 40px 40px 24px 0px;
              padding: 8px 24px;
              border-radius: 5px;
              box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
                -5px -5px 8px 0 var(--b-w-6);
              background-color: var(--b-w-5);
              line-height: normal;
              color: var(--primary-1);
              font-family: "Poppins", sans-serif;
              font-size: 14px;
              font-weight: bold;
              border: none;
              text-transform: uppercase;
            }
          }
        }
      }

      .form-left-bord-gray {
        border-left: 10px solid var(--b-w-3);
      }

      .form-left-bord-green {
        border-left: 10px solid var(--success-light);
        padding: 40px;
      }

      .info-right {
        width: 25%;
        height: fit-content;
        margin: 0px 0px 0px 24px;
        padding: 24px 14px 21px 16px;
        border-radius: 10px;
        box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
          -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
        background-color: var(--b-w-6);

        .comment {
          display: block;
          margin: 16px 2px 13px 0;
          font-family: "Poppins", sans-serif;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-2);
        }

        .rightChartSaving {
          text-align: center;
          .topCurrencySymbol {
            font-family: Poppins;
            font-style: italic;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;

            color: #1d3557;
          }
          .topAmount {
            font-family: Poppins;
            font-style: italic;
            font-weight: 300;
            font-size: 24px;
            line-height: 36px;
            /* identical to box height */
            text-align: center;

            color: #1d3557;
          }
          .bottomAmount {
            font-family: Poppins;
            font-style: italic;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-align: center;

            color: #777274;
          }
        }

        .rightSavingTypes {
          .rightSavingTypesTitle {
            font-family: Poppins;
            font-style: italic;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            /* identical to box height */

            color: #000000;
          }
          .rightSavingTypesAmount {
            font-family: Poppins;
            font-style: italic;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;

            color: #777274;
          }
        }

        .border-option {
          margin-top: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid var(--b-w-3);
        }

        .total-gain {
          margin: 19px 2px 10px 0;
          font-family: "Poppins", sans-serif;
          font-size: 32px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-1);
        }

        .dot {
          height: 16px;
          width: 16px;
          margin: 5px 10px 0px 0px;
          border-radius: 50%;
          display: inline-block;
        }

        .dot-black {
          background-color: var(--primary-2);
        }

        .dot-blue {
          background-color: var(--primary-1);
        }

        .dot-softblue {
          background-color: var(--links-light);
        }

        .dot-red {
          background-color: var(--danger-light);
        }

        .dot-orange {
          background-color: var(--warning-light);
        }

        .dot-yellow {
          background-color: var(--alert-light);
        }

        .dot-green {
          background-color: var(--success-light);
        }
      }

      .summary {
        width: 100%;
        height: fit-content;
        padding: 24px 15px 32px 0;
        border-radius: 10px;
        box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
          -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
        background-color: var(--b-w-6);

        .form-message {
          margin: 0 0 16px;
          font-family: "Open Sans", sans-serif;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.63;
          letter-spacing: normal;
          color: var(--primary-2);
        }

        .form-title {
          margin: 0 167px 34px 23px;
          font-family: "Open Sans", sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: #122745;
        }

        .form-row {
          min-height: 50px;
          border-bottom: 1px solid var(--b-w-3);
          display: flex;
          justify-content: space-around;

          &:last-child {
            border-bottom: none;
          }

          .icon {
            margin: 40px 0px 0px 30px;
          }

          .off-item {
            width: 40%;
          }

          .pad-left {
            padding-left: 15%;
          }

          .item {
            margin: 24px 24px 30px 0px;
            max-width: 100%;
            display: flex;
            flex-direction: column;

            .value {
              font-weight: bold;

              .input-normal {
                width: 100%;
                margin: 0px 0px 0px 0px;
                padding: 9px 9px 8px 10px;
                border-radius: 5px;
                border: solid 1px var(--success-dark);
                background-color: var(--b-w-6);
              }

              .input-info {
                width: 90%;
                margin: 8px 0px 0px 0px;
                padding: 9px 9px 8px 10px;
                border-radius: 5px;
                border: solid 1px var(--b-w-4);
                background-color: var(--b-w-6);
              }

              .input-normal::placeholder {
                font-family: "Poppins", sans-serif;
                font-size: 16px;
                font-weight: 500;
                font-stretch: normal;
                font-style: italic;
                line-height: normal;
                letter-spacing: normal;
                color: var(--b-w-3);
              }
            }

            .title {
              margin-top: 15px;
              font-family: "Poppins", sans-serif;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: italic;
              line-height: normal;
              letter-spacing: normal;
              color: var(--b-w-2);
            }

            .sub-title {
              margin-top: 20px;
              font-family: "Open Sans", sans-serif;
              font-size: 16px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.63;
              letter-spacing: normal;
              color: var(--primary-2);
            }

            .data-focus {
              margin-top: 15px;
              font-family: "Poppins", sans-serif;
              font-size: 18px;
              font-weight: 500;
              font-stretch: normal;
              font-style: italic;
              line-height: normal;
              letter-spacing: normal;
              color: var(--b-w-1);
            }

            .value-data {
              font-family: "Poppins", sans-serif;
              font-size: 16px;
              font-weight: 500;
              font-stretch: normal;
              font-style: italic;
              line-height: normal;
              letter-spacing: normal;
              color: var(--b-w-1);
            }

            .value-data-2 {
              margin-top: 10px;
            }

            .value-flex {
              display: inline;
            }

            .value-info {
              font-family: "Poppins", sans-serif;
              font-size: 32px;
              font-weight: 300;
              font-stretch: normal;
              font-style: italic;
              line-height: normal;
              letter-spacing: normal;
              color: var(--b-w-1);
            }

            .button-more {
              width: 344px;
              height: 39px;
              margin: 40px 40px 24px 0px;
              padding: 8px 24px;
              border-radius: 5px;
              box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
                -5px -5px 8px 0 var(--b-w-6);
              background-color: var(--b-w-5);
              line-height: normal;
              letter-spacing: 1px;
              color: var(--primary-1);
              font-family: "Poppins", sans-serif;
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 1px;
              border-color: white;
            }
          }
        }

        .comment {
          display: block;
          margin: 16px 2px 13px 0;
          font-family: "Poppins", sans-serif;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-2);
        }

        .border-option {
          margin-top: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid var(--b-w-3);
        }

        .total-gain {
          margin: 19px 2px 10px 0;
          font-family: "Poppins", sans-serif;
          font-size: 32px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-1);

          .currencySymbol {
            font-family: Poppins;
            font-style: italic;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            color: #000000;
          }
        }

        .dot {
          height: 16px;
          width: 16px;
          margin: 16px 16px 0px 1px;
          border-radius: 50%;
          display: inline-block;
        }

        .dot-black {
          background-color: var(--primary-2);
        }

        .dot-blue {
          background-color: var(--primary-1);
        }

        .dot-softblue {
          background-color: var(--links-light);
        }

        .dot-red {
          background-color: var(--danger-light);
        }

        .dot-orange {
          background-color: var(--warning-light);
        }

        .dot-yellow {
          background-color: var(--alert-light);
        }

        .dot-green {
          background-color: var(--success-light);
        }
      }
    }
  }
}

.temp {
  .Rectangle-Copy-2 {
    width: 1006px;
    height: 322px;
    margin: 234px 137px 60px;
    padding: 40px 32px 40px 103px;
    border-radius: 10px;
    box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5);
    background-color: var(--b-w-6);
  }
}
