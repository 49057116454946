.load {
  * {
    font-family: Poppins;
  }

  .loadHistory {
    margin-top: 100px;
    width: 100%;
  }
  display: flex;
  flex: 1;
  justify-content: center;

  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  .loadHeroContent {
    width: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    background: url("../../../assets/images/employee/loanHero.svg") no-repeat
        left -70px center,
      linear-gradient(to top, #048aa8 3%, #1d3557) !important;
    height: 470px;
  }
  .loadContainer {
    display: flex;
    flex-direction: column;
    width: 65vw;
    padding: 4vh 8vh;

    .breadcrumbs {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding-right: 18px;
        padding-left: 18px;
        border-right: 2px solid;

        a {
          text-decoration: none;
          color: inherit;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .loanHistoryContainer {
    display: flex;
    flex-direction: column;
    width: 65vw;
  }
  .loadHero {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: 600px;
    height: calc(60vh - 80px);
    justify-content: space-between;

    .content {
      padding: 4vh;
      padding-left: 0;

      .contentTitle {
        font-size: 48px;
        font-weight: bold;
        font-style: italic;
      }

      p {
        line-height: 200%;
        margin-bottom: 2rem;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        color: var(--b-w-5);
      }

      ul {
        padding: 0;
        list-style-position: inside;
        list-style-type: none;
        font-weight: 400;
      }

      li {
        padding-left: 1.3em;
      }

      li:before {
        //content: "-"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        color: var(--success-dark);
      }

      .text-style-1 {
        font-weight: 500;
        font-style: italic;
      }
    }

    .sideTableContainer {
      display: flex;
      justify-content: center;
      z-index: 1;

      .sideTableContent {
        width: 216px;
        max-width: 216px;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: self-start;
        text-align: center;
        background-color: white;
        border-radius: 15px;
        padding: 2rem;
        margin-top: 4rem;
        border: 1px solid #ececec;

        .sideItem {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 1rem;

          .key {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 500;
            font-style: italic;
            color: var(--b-w-2) !important;
          }

          .value {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 500;
            font-style: italic;
            color: var(--b-w-1);

            &.green {
              color: var(--success-dark);
            }

            .money {
              font-size: 16px;
            }
          }
        }

        button {
          font-family: Poppins;
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 1px;
          color: var(--primary-1);
          padding: 0.5rem 1rem;
          border-color: white;
          width: 100%;
          border-radius: 5px;
          box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
            -5px -5px 8px 0 var(--b-w-6);
          background-color: var(--b-w-6);
          border-width: 0;
        }

        hr {
          width: 100%;
          border: solid 1px var(--b-w-4);
        }
      }
    }
  }
  .loadHistoryContent {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    padding: 30px;
  }
  .loadMaxHistory {
    h2 {
      font-size: 24px;
      font-weight: 300;
      font-style: italic;
      color: #122745;
    }
    .loadHistoryLine {
      width: 100%;
      height: 1px;
      background-color: #ececec;
    }
    .PaymentNoRegister {
      p {
        font-weight: bold;
        font-size: 32px;
        font-style: italic;
        color: #888888;
        text-align: center;
        margin: 3rem 0;
      }
    }
  }
  .PaymentRegisterContainer {
    width: 100%;
    padding: 1rem 1rem;
    border-radius: 10px;

    margin: 2rem 0 2rem 0;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5);
    .headerTabPrintItem {
      width: 5%;

      .arrow {
        width: 50px;
        height: 50px;
        margin: 20px auto 0px auto;
        border-radius: 10px;
        box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
          -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
        color: #777274;
      }
    }
  }
  .PaymentRegister {
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
    .tableTitle {
      color: #777274;
      font-size: 12px;
      font-style: italic;
      font-weight: 500;
    }
    .status {
      width: 20%;
    }
    .exped_date {
      width: 30%;
    }
    .amount {
      width: 30%;
    }
    .date {
      width: 30%;
      .amountLoad {
        color: black;
        span {
          font-size: 15px;
        }
      }
    }
    .statusLoad {
      width: 64px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      font-size: 12px;
      font-style: italic;
      text-align: center;
    }

    .toBeApproved {
      width: fit-content;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      color: #ffffff;
      padding: 8px;
      display: table;
      margin-bottom: 5px;
      background-color: #ffc300;
    }
    .dispersado {
      width: fit-content;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      color: #ffffff;
      padding: 8px;
      display: table;
      margin-bottom: 5px;
      background-color: #ffc300;
    }
    .approved {
      width: fit-content;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      color: #ffffff;
      padding: 8px;
      display: table;
      margin-bottom: 5px;
      background-color: #15db93;
    }
    .autorizado {
      width: fit-content;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      color: #ffffff;
      padding: 8px;
      display: table;
      margin-bottom: 5px;
      background-color: #15db93;
    }
    .rejected {
      width: fit-content;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      color: #ffffff;
      padding: 8px;
      display: table;
      margin-bottom: 5px;
      background-color: #d9484e;
    }
    .paid {
      width: fit-content;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      color: #ffffff;
      padding: 8px;
      display: table;
      margin-bottom: 5px;
      background-color: #4897d9;
    }

    .amountLoad {
      color: #777274;
      font-size: 32px;
      font-weight: 300;
      font-style: italic;
      margin: 0;
    }
    .dateLoad {
      color: black;
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      font-style: italic;
    }
  }
  .loadHistoryPayments {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .loadHistoryPaymentsHeader {
    padding: 1rem;
    background-color: #ececec;
    color: #777274;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    font-style: italic;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .loadHistoryPaymentsTitles {
    padding: 1rem;
    background-color: #fafafa;
    color: #777274;
    font-weight: 500;
    display: flex;
    text-align: center;
    font-size: 12px;
    font-style: italic;
    border: 1px solid #ececec;
    .loadHistoryPaymentsTitle {
      width: calc(100% / 6);
      &:first-child {
        width: calc(100% / 7);
      }
      &:last-child {
        width: calc(100% / 5);
      }
    }
  }
  .loadHistoryPaymentsBody {
    padding: 1rem;
    background-color: white;
    color: black;
    font-weight: bold;
    display: flex;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
    .loadHistoryPaymentsItem {
      width: calc(100% / 6);
      &:first-child {
        width: calc(100% / 7);
      }
      &:last-child {
        width: calc(100% / 5);
      }
    }
  }
}

.hidden {
  display: none;
}

/*
/// the first 'th' within the first 'tr' of the 'thead':
thead tr:first-child th:first-child {
    border-radius: 0.6em 0 0 0;
    border: solid 1px var(--success-dark);
}
/// the last 'th' within the first 'tr' of the 'thead':
thead tr:first-child th:last-child {
    border-radius: 0 0.6em 0 0;
}
/// the first 'td' within the last 'tr' of the 'tbody':
tbody tr:last-child td:first-child {
    border-radius: 0 0 0 0.6em;
}
/// the last 'td' within the last 'tr' of the 'tbody':
tbody tr:last-child td:last-child {
    border-radius: 0 0 0.6em 0;
    border: solid 1px var(--success-dark);
}*/

.tg {
  border: solid 1px var(--success-dark);
  border-collapse: collapse;
  border-spacing: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 5%;

  box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
    -5px -5px 8px 0 var(--b-w-6);
}
.tg td {
  border: solid 1px var(--success-dark);
  border-collapse: separate;
  font-family: Poppins;
  font-size: 14px;
  overflow: hidden;
  padding: 0px 20px;
  word-break: normal;
  color: black;
}
.tg th {
  border: solid 1px var(--success-dark);
  border-collapse: separate;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  padding: 0px 20px;
  word-break: normal;
  color: black;
}
.tg .tg-zlqz {
  background-color: var(--primary-1);
  font-weight: bold;
  color: white;
  text-align: center;
  vertical-align: top;
}

.tg .tg-c3ow {
  text-align: center;
  vertical-align: top;
  color: black;
  padding: 10px 20px;
}
.tg .tg-0pky {
  text-align: left;
  vertical-align: top;
  color: black;
  padding: 0px 20px;
}

.form-container-employee-loan {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: left;

  .sub-data-form {
    margin: 14px 24px 0px 0px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    font-style: italic;
    text-align: left;
    color: var(--b-w-2);
  }

  .form-input {
    margin: 14px 24px 14px 0px;
    padding: 8px 17px 9px 15px;
    border-radius: 5px;
    border: solid 1px var(--success-dark);
    background-color: var(--b-w-6);
  }
}
