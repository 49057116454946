.grid-savings-table {
  display: grid;
  grid-template-columns: 130px repeat(5, minmax(0, 1fr));
}

.company-item {
  &:hover {
    cursor: pointer;
    background-color: #122745;
    color: white;
  }
}

.savingsAdmin {
  * {
    font-family: Poppins;
  }
  display: flex;
  flex: 1;
  justify-content: center;

  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to top, white 97%, white) !important;
  .companiesHeroContent {
    width: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    background-image: linear-gradient(to top, black 97%, black);
    height: 300px;
  }
  .companiesContainer {
    display: flex;
    flex-direction: column;
    width: 65vw;
    padding: 4vh 8vh;

    .breadcrumbs {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding-right: 18px;
        padding-left: 18px;
        border-right: 1px solid #fafafa;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        color: #fafafa;

        a {
          text-decoration: none;
          color: inherit;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .companiesHero {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: 600px;
    height: calc(60vh - 80px);
    justify-content: space-between;

    .content {
      padding: 4vh;
      padding-left: 0;

      .contentTitle {
        font-size: 48px;
        font-weight: bold;
        font-style: italic;
      }

      p {
        line-height: 200%;
        margin-bottom: 2rem;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        color: var(--b-w-5);
      }

      ul {
        padding: 0;
        list-style-position: inside;
        list-style-type: none;
        font-weight: 400;
      }

      li {
        padding-left: 1.3em;
      }

      li:before {
        content: "-"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        color: var(--success-dark);
      }

      .text-style-1 {
        font-weight: 500;
        font-style: italic;
      }
    }
  }
  .companiesContent {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #fafafa;
    .companiesContentData {
      width: 65vw;
      border-radius: 5px;
      margin-top: -5%;
      margin-bottom: 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .searchCompany {
    flex: 2;
    position: relative;
    border-radius: 5px;
    background-color: var(--b-w-6);

    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--b-w-1);
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5);
    margin-top: 1rem;
    width: 100%;
    &:hover {
      background-color: whitesmoke;
    }
  }

  .searchIconCompany {
    padding: 0 16px;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    color: var(--b-w-3);
    max-height: 800px;
    overflow: auto;
  }
  .white_button {
    font-family: Poppins;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--primary-1);
    padding: 0.5rem 1rem;
    border-color: white;
    width: 210px;
    border-radius: 5px;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -5px -5px 8px 0 var(--b-w-6);
    background-color: var(--b-w-6);
    border-width: 0;
    height: 39px;
  }
  .grayLine {
    width: 100%;
    height: 1px;
    margin: 1.2rem 8px 1rem 0;
    background-color: #ececec;
  }
  .bigLine {
    height: 2px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .listOfCompanies {
    margin-top: 1rem;
  }

  .principalContentCompany {
    background-color: white;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -2px 1px 8px 0 var(--b-w-6);
    width: 100%;
    border-radius: 15px;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  // COMPANY UNSELECTED
  .savingsContent {
    width: 100%;
    padding: 2rem;
    .savings_title {
      color: #000;
      font-size: 24px;
      font-weight: 300;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      margin: 0;
    }
    .grayText {
      color: #777274;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      margin: 0;
    }
    .tabHeader {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      .tabItem {
        width: calc(100% / 5);
        display: flex;
        flex-direction: column;
        justify-content: center;
        img {
          width: 75px;
          height: 60px;
        }
        &:first-child {
          width: 10%;
        }
        .companyName {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
        }
        .blackText {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;
          .smallText {
            font-size: 16px;
            font-weight: 500;
            margin-right: 8px;
            margin-top: 6px;
          }
          .greenText {
            color: #11b78c;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: italic;
            margin-left: 8px;
          }
        }
      }
    }
    .tableBodyHeader {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-top: 1rem;
      .tabItem {
        width: calc(100% / 5);
        &:nth-child(2) {
          width: 10%;
        }
      }
    }
    .tableBody {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      margin-top: 1rem;
      .tabItem {
        width: calc(100% / 5);
        &:nth-child(2) {
          width: 10%;
        }
        .circleText {
          display: flex;
          align-items: center;
        }
        .blackText {
          color: #000;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          .circle {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 1rem;
          }
          .blueDarkColor {
            background-color: #1d3557;
          }
          .blueMediumColor {
            background-color: #048aa8;
          }
          .blueLightColor {
            background-color: #6cb6f5;
          }
          .pinkColor {
            background-color: #ef476f;
          }
          .orangeColor {
            background-color: #fa7e58;
          }
          .yellowColor {
            background-color: #f5a61d;
          }
          .greenColor {
            background-color: #15db93;
          }
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
  .totalComapniesTitle {
    width: 100%;
    color: #000;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    margin: 0;
  }
  .companiesHeader {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    .item {
      width: 18%;
      &:first-child {
        width: 40%;
      }
    }
    .grayDarkText {
      color: #777274;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      span {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
      }
      .greenText {
        color: #11b78c;
      }
    }
  }
  .companyDescription {
    width: 100%;
    border-radius: 5px;
    box-shadow: 6px 6px 16px 0 rgba(166, 171, 189, 0.5);
    background-color: #fff;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    cursor: pointer;
    .paddingContent {
      padding: 1.5rem;
      width: 100%;
      display: flex;
      .imageText {
        img {
          width: 70px;
          height: 65px;
        }
        p {
          margin: 0 0 0 1rem;
        }
      }
      .item {
        width: 18%;
        display: flex;
        align-items: center;
        &:first-child {
          width: 40%;
        }
        .itemCompanyName {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
        }
        .blackText {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;
          .smallText {
            font-size: 16px;
            font-weight: 500;
            margin-right: 8px;
            margin-top: 6px;
          }
          .greenText {
            color: #11b78c;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: italic;
            margin-left: 8px;
          }
        }
      }
      .arrowIcon {
        display: flex;
        align-items: center;
        color: #000;
        margin-left: 2rem;
      }
    }
  }
  .itemUnSelected {
    opacity: 0.6;
  }
  .companySelected {
    opacity: 1;
  }
  .companyUnSelected {
    background-color: rgba(166, 171, 189, 0.3);
  }
  // white box
  .whiteShadowBox {
    padding: 16px;
    border-radius: 5px;
    box-shadow: 6px 6px 16px 0 rgba(166, 171, 189, 0.5);
    background-color: white;
    margin-top: 1rem;
    .bigSubtitle {
      margin: 0;
      font-size: 24px;
      font-weight: 300;
      font-stretch: normal;
      font-style: italic;
      color: #000;
    }
  }
}
