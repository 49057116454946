.button {
  color: black;
  &:hover {
    background-color: #122745;
    color: white;
  }
}

.green-active {
  border-radius: 5px;
  background-color: var(--success-dark);
  width: fit-content;
  padding: 5px;
  color: white;
}

.gray-active {
  border-radius: 5px;
  background-color: var(--b-w-2);
  width: fit-content;
  padding: 5px;
  color: white;
}
