.mainContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  background-color: var(--b-w-5);
  flex-direction: column;
}

.button-saving {
  &:hover {
    background-color: #122745;
    color: white;
  }
}

.hero {
  display: flex;
  align-items: flex-start;
  min-height: 7em;
  mix-blend-mode: multiply;
  background: linear-gradient(to top, #048aa8 3%, #1d3557) !important;
  font-size: 48px;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  color: var(--b-w-6);
  padding-left: 200px;
  padding-top: 1em;

  .hero-block {
    display: flex;

    .hero-subBlock {
      display: flex;
      flex-direction: column;
      padding-left: 1em;
    }

    img {
      width: 2em;
      height: auto;
    }

    .greeting {
      text-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
      font-family: Poppins;
      font-size: 36px;
    }

    .welcome {
      text-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
      font-family: Poppins;
      font-size: 48px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .userName {
      font-weight: 500;
      font-family: Poppins;
      font-size: 36px;
    }
  }
}

.home-resume {
  background-color: var(--b-w-5);
  display: flex;
  width: 800px;

  .form-left {
    z-index: 2;
    width: 80%;
    height: fit-content;
    padding: 24px 15px 32px 0;
    border-radius: 10px;
    box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5);
    background-color: var(--b-w-6);

    .border-option {
      border-bottom: 1px solid var(--b-w-3);
    }

    .form-message {
      margin: 0 0 1em 32px;
      font-family: Poppins;
      font-size: 24px;
      font-weight: 300;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      color: #122745;
    }

    .form-title {
      margin: 0 167px 34px 23px;
      font-family: OpenSans;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      color: #122745;
    }

    .form-row {
      padding-left: 2em;
      min-height: 50px;
      border-bottom: 1px solid var(--b-w-3);
      display: flex;
      justify-content: stretch;

      &:last-child {
        border-bottom: none;
      }

      .icon {
        margin: 40px 0px 0px 30px;
      }

      .off-item {
        width: 40%;
      }

      .pad-left {
        padding-left: 15%;
      }

      .item {
        margin: 1em 0 1em 0;
        max-width: 100%;
        display: flex;
        flex-direction: column;

        .home-subtitle {
          font-size: 2em;
        }

        .value {
          font-weight: bold;

          .input-normal {
            width: 100%;
            margin: 8px 0px 0px 0px;
            padding: 9px 9px 8px 10px;
            border-radius: 5px;
            border: solid 1px var(--success-dark);
            background-color: var(--b-w-6);
          }

          .input-info {
            width: 90%;
            margin: 8px 0px 0px 0px;
            padding: 9px 9px 8px 10px;
            border-radius: 5px;
            border: solid 1px var(--b-w-4);
            background-color: var(--b-w-6);
          }

          .input-pack {
            display: inline-flex;
          }

          .input-percent {
            width: 60%;
            margin: 8px 0px 0px 0px;
            padding: 9px 9px 8px 10px;
            border-radius: 5px;
            border: solid 1px var(--b-w-4);
            background-color: var(--b-w-6);
          }

          .input-delete {
            width: 20%;
            margin: 8px 0px 0px 10px;
            padding: 9px 9px 8px 10px;
            border-radius: 5px;
            border: solid 1px var(--b-w-4);
            background-color: var(--b-w-6);
          }

          .input-normal::placeholder {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: italic;
            line-height: normal;
            letter-spacing: normal;
            color: var(--b-w-3);
          }
        }

        .title {
          font-family: Poppins;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-2);
        }

        .value-data {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-1);
        }

        .value-data-2 {
          margin-top: 10px;
        }

        .value-info {
          font-family: Poppins;
          font-size: 32px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-1);
        }

        .dotter {
          font-weight: bold;
          margin: 16px 16px 1px 0px;
          display: flex;
          align-items: center;

          .savingType {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: italic;
            line-height: normal;
            letter-spacing: normal;
          }

          .data-focus {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: italic;
            line-height: normal;
            letter-spacing: normal;

            .percent {
              font-size: 12px;
            }
          }

          .dot {
            height: 16px;
            width: 16px;
            margin-right: 16px;
            border-radius: 50%;
            display: inline-block;
          }

          .dot-black {
            background-color: var(--primary-2);
          }

          .dot-blue {
            background-color: var(--primary-1);
          }

          .dot-softblue {
            background-color: var(--links-light);
          }

          .dot-red {
            background-color: var(--danger-light);
          }

          .dot-orange {
            background-color: var(--warning-light);
          }

          .dot-yellow {
            background-color: var(--alert-light);
          }

          .dot-green {
            background-color: var(--success-light);
          }
        }
      }
    }
  }

  .info-right {
    z-index: 2;
    width: 20%;

    .box-right {
      z-index: 2;
      width: 100%;
      height: fit-content;
      margin: 0px 0px 24px 24px;
      padding: 24px 14px 21px 16px;
      border-radius: 10px;
      box-shadow: 10px 10px 10px 0 #a6abbd80;
      background-color: var(--b-w-6);

      .form-message {
        margin: 0 0 16px 0;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 300;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: #122745;
      }

      .comment {
        display: block;
        margin: 16px 2px 13px 0;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: var(--b-w-2);
      }

      .important-info {
        margin: 19px 2px 10px 0;
        font-family: Poppins;
        font-size: 32px;
        font-weight: 300;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: var(--b-w-1);

        .numberTop {
        }

        .slash {
          font-size: 12px;
          margin-left: -8px;
        }

        .numberBottom {
          font-size: 12px;
        }

        .currencySymbol {
          font-size: 16px;
        }
      }
    }
  }
}

.dot {
  height: 12px;
  width: 12px;
  margin: 12px 12px 0px 0px;
  border-radius: 50%;
  display: inline-block;
}

.dot-black {
  background-color: var(--primary-2);
}

.dot-blue {
  background-color: var(--primary-1);
}

.dot-softblue {
  background-color: var(--links-light);
}

.dot-red {
  background-color: var(--danger-light);
}

.dot-orange {
  background-color: var(--warning-light);
}

.dot-yellow {
  background-color: var(--alert-light);
}

.dot-green {
  background-color: var(--success-light);
}
