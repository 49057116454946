.reset-password {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__container {
    background-color: white;
  }

  &__alert-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100px;
    }
    span {
      margin-top: 30px;
      width: 100%;
      height: 60px;
      background-color: #f5fbff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.form__label {
  color: #1d1d1f;
  padding: 10px 0;
}
.form_input {
  border: 1px solid rgb(155, 155, 161);
  height: 30px;
  border-radius: 10px;
  padding: 10px;
}

.btn__container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn-send {
  width: 200px;
  margin-top: 20px;
  padding: 10px;
  background-color: #048aa8;
  color: white;
  border-radius: 5px;
}
