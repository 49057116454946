.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;

  &__container {
    width: 60%;
    font-family: Poppins;
    width: 744px;
    height: 438px;
    padding: 40px 32px 32px;
    border-radius: 10px;
    box-shadow: 10px 10px 25px 0 rgba(233, 233, 233, 0.9),
      -10px -10px 20px 0 rgba(255, 255, 255, 0.9),
      10px -10px 20px 0 rgba(233, 233, 233, 0.2),
      -10px 10px 20px 0 rgba(233, 233, 233, 0.2),
      inset -1px -1px 2px 0 rgba(233, 233, 233, 0.5),
      inset 1px 1px 2px 0 rgba(255, 255, 255, 0.3);
    background-color: #fafafa;

    &-icon {
      text-align: center;
      margin-top: -14%;
    }

    h1 {
      margin: 0;
      color: #1d3557;
      font-family: Poppins;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
    }

    h2 {
      margin-top: 16px;
      color: #4e4e4e;
      font-family: Poppins;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .form_row {
      display: flex;

      .form__group {
        width: 50%;
      }
    }

    .form_label {
      font-family: Poppins;
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: italic;
    }

    .form_input {
      width: 324px;
      height: 47px;
      margin: 8px 0 0;
      border-radius: 20px;
      border: solid 1px #e5e5e5;
      background-color: #fff;
      padding: 0 16px;
      font-size: 14px;
      color: #8d8d8d;
    }

    .input-large {
      width: 94%;
    }

    .primary-btn {
      width: 99%;
      height: 48px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin: 32px 0 0;
      padding: 12px 155px;
      border-radius: 50px;
      background-color: #048aa8;
      border: none;
      color: #fff;
      font-weight: bold;
      font-style: italic;
    }
  }

  .imagen {
    text-align: center;
    padding-top: 100px;
  }
}
