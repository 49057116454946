.grid-savings-table {
  display: grid;
  grid-template-columns: 130px repeat(5, minmax(0, 1fr));
}

.company-item {
  &:hover {
    cursor: pointer;
    background-color: #122745;
    color: white;
  }
}

.bossSavingContent {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  font-family: Poppins;

  .saving-resume-blue {
    width: 100%;
    background: url("../../../assets/images/company/savingbackimg.svg")
        no-repeat left -70px top,
      linear-gradient(to top, #048aa8 3%, #048aa8) !important;
    display: flex;
    justify-content: center;
    min-height: 300px;

    .maxContHer {
      width: 70%;
      padding: 4vh 8vh;
    }

    .breadcrumbs {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 300;
      color: var(--b-w-5);

      li {
        padding-right: 18px;
        padding-left: 18px;
        border-right: 2px solid;

        a {
          text-decoration: none;
          color: inherit;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    .savings-hero {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .content {
        padding: 4vh;
        padding-left: 0;

        .content-title {
          font-family: Poppins;
          font-size: 48px;
          font-weight: bold;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-6);
        }
      }
    }
  }
  .savings-container-secondPart {
    width: 80%;
    padding: 4vh 8vh;

    .form-left {
      height: fit-content;
      padding: 1rem;
      border-radius: 10px;
      box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
        -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
      margin-bottom: 2rem;
    }

    .headerTabPrint {
      // TABLE
      width: 100%;
      display: flex;
      justify-content: center;
      cursor: pointer;

      .headerTabPrintItemVoid {
        width: 5%;
      }

      .headerTabPrintItem {
        width: 10%;
      }
      .headerTabPrintBigItem {
        width: 25%;
      }
      .headerTabPrintMidItem {
        width: 20%;
      }
      .headerTabPrintItemArrow {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;

        .arrow {
          width: 30px;
          height: 30px;
          border-radius: 10px;
          // box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5), -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
        }
      }
    }

    .headerTabPrintItemSmallTitle {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      color: var(--b-w-2);
    }

    .headerTabPrintItemMediumTitle {
      font-size: 14px !important;
    }

    .headerTabPrintItemBigTitle {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      color: var(--b-w-1);
      span {
        font-size: 12px;
        font-weight: bold;
      }
    }

    .notAvailable {
      font-weight: 300;
      color: var(--b-w-3);
    }

    .bodyTabPrint {
      padding: 0 12%;

      .grayLine {
        width: 100%;
        height: 1px;
      }
    }

    .bodyTabPrintContent {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 1rem;

      .bodyTabPrintElement {
        width: 25%;
      }
    }

    .savingsHistoryPayments {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0;
    }

    .savingsHistoryPaymentsHeader {
      display: flex;
      padding: 2rem 1rem 0.5rem 1rem;
      color: #777274;
      font-weight: bold;
      font-size: 12px;
      font-style: italic;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .savingsHistoryPaymentsTitle {
      width: calc(100% / 3);
      margin: auto;
      color: #777274;
      font-weight: 500;
      display: flex;
      font-size: 12px;
      font-style: italic;
    }

    .savingsHistoryPaymentsBody {
      padding: 1rem;
      background-color: white;
      color: black;
      font-weight: bold;
      display: flex;

      font-size: 14px;
      font-weight: 500;
      font-style: italic;

      .savingsHistoryPaymentsItem {
        width: calc(100% / 3);
      }

      .greenText {
        color: #11b78c;
      }
    }

    .form-left-bord-black {
      border-left: 10px solid var(--primary-2);
    }

    .form-left-bord-blue {
      border-left: 10px solid var(--primary-1);
    }

    .form-left-bord-soft-blue {
      border-left: 10px solid var(--links-light);
    }

    .form-left-bord-red {
      border-left: 10px solid var(--danger-light);
    }

    .form-left-bord-orange {
      border-left: 10px solid var(--warning-light);
    }

    .form-left-bord-yellow {
      border-left: 10px solid var(--alert-light);
    }

    .form-left-bord-gray {
      border-left: 10px solid var(--b-w-3);
    }

    .form-left-bord-green {
      border-left: 10px solid var(--success-light);
    }

    .info-right {
      width: 20%;
      height: fit-content;
      margin: 0px 0px 0px 24px;
      padding: 24px 14px 21px 16px;
      border-radius: 10px;
      box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
        -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
      background-color: var(--b-w-6);

      .comment {
        display: block;
        margin: 16px 2px 13px 0;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: var(--b-w-2);
      }

      .border-option {
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--b-w-3);
      }

      .total-gain {
        margin: 19px 2px 10px 0;
        font-family: Poppins;
        font-size: 32px;
        font-weight: 300;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: var(--b-w-1);
      }

      .dot {
        height: 16px;
        width: 16px;
        margin: 16px 16px 0px 1px;
        border-radius: 50%;
        display: inline-block;
      }

      .dot-black {
        background-color: var(--primary-2);
      }

      .dot-blue {
        background-color: var(--primary-1);
      }

      .dot-softblue {
        background-color: var(--links-light);
      }

      .dot-red {
        background-color: var(--danger-light);
      }

      .dot-orange {
        background-color: var(--warning-light);
      }

      .dot-yellow {
        background-color: var(--alert-light);
      }

      .dot-green {
        background-color: var(--success-light);
      }
    }
  }

  .generalTitle {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #122745;
  }
}

.savings-container-resume {
  display: flex;
  flex-direction: column;
  padding: 4vh 8vh;
  width: 80%;
  margin-top: -8rem;
}

.saving-resume-white {
  width: 100%;

  .savings-resume {
    width: 100%;
    height: fit-content;
    padding: 24px 0 32px 0;
    border-radius: 10px;
    box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5);
    background-color: var(--b-w-6);

    .titleIcon {
      display: flex;
      flex-direction: row;
      display: flex;
      align-items: center;
      padding: 0 2rem;
      justify-content: space-between;

      .savingsTitle {
        font-family: Poppins;
        font-style: italic;
        font-weight: 300;
        font-size: 24px;
        line-height: 36px;
      }
    }

    .form-message {
      margin: 0 0 16px;
      font-family: OpenSans;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: var(--primary-2);
    }

    .form-title {
      margin: 0 167px 34px 23px;
      font-family: OpenSans;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      color: #122745;
    }

    .form-row {
      min-height: 50px;
      border-bottom: 1px solid var(--b-w-3);
      padding: 0 2rem;
      display: flex;
      justify-content: space-around;

      &:last-child {
        border-bottom: none;
      }

      .icon {
        margin: 40px 0px 0px 30px;
      }

      .off-item {
        width: 33%;
        padding: 0 1rem;
      }

      .pad-left {
        padding-left: 15%;
      }

      .item {
        margin: 24px 24px 30px 0px;
        max-width: 100%;
        display: flex;
        flex-direction: column;

        .value {
          font-weight: bold;

          .input-normal {
            width: 100%;
            margin: 8px 0px 0px 0px;
            padding: 9px 9px 8px 10px;
            border-radius: 5px;
            border: solid 1px var(--success-dark);
            background-color: var(--b-w-6);
          }

          .input-info {
            width: 90%;
            margin: 8px 0px 0px 0px;
            padding: 9px 9px 8px 10px;
            border-radius: 5px;
            border: solid 1px var(--b-w-4);
            background-color: var(--b-w-6);
          }

          .input-normal::placeholder {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: italic;
            line-height: normal;
            letter-spacing: normal;
            color: var(--b-w-3);
          }
        }

        .title {
          margin-top: 15px;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          color: var(--b-w-2);
          margin-bottom: 1rem;
        }

        .sub-title {
          margin-top: 20px;
          font-family: OpenSans;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.63;
          letter-spacing: normal;
          color: var(--primary-2);
        }

        .comment {
          display: block;
          margin: 16px 2px 13px 0;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-2);
        }

        .border-option {
          margin-top: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid var(--b-w-3);
        }

        .aportaciones {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: #122745;
        }

        .total-gain {
          margin: 19px 2px 10px 0;
          font-family: Poppins;
          font-size: 32px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-1);
        }

        .element {
          color: #777274;

          b {
            color: black;
          }
        }

        .dot {
          height: 16px;
          width: 16px;
          margin: 16px 16px 0px 1px;
          border-radius: 50%;
          display: inline-block;
        }

        .dot-black {
          background-color: var(--primary-2);
        }

        .dot-blue {
          background-color: var(--primary-1);
        }

        .dot-softblue {
          background-color: var(--links-light);
        }

        .dot-red {
          background-color: var(--danger-light);
        }

        .dot-orange {
          background-color: var(--warning-light);
        }

        .dot-yellow {
          background-color: var(--alert-light);
        }

        .dot-green {
          background-color: var(--success-light);
        }
      }
    }
  }
}
