.printContent {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  font-family: Poppins;

  .saving-resume-blue {
    width: 100%;
    background: url("../../../../assets/images/employee/savingbackimg.svg")
        no-repeat left -70px top,
      linear-gradient(to top, #048aa8 3%, #1d3557) !important;
    display: flex;
    justify-content: center;
    min-height: 300px;

    .maxContHer {
      width: 70%;
      padding: 4vh 8vh;
    }

    .breadcrumbs {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 100;
      color: var(--b-w-5);

      li {
        padding-right: 18px;
        padding-left: 18px;
        border-right: 2px solid;

        a {
          text-decoration: none;
          color: inherit;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }

    .savings-hero {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      .content {
        padding: 4vh;
        padding-left: 0;

        .content-title {
          font-family: Poppins;
          font-size: 48px;
          font-weight: bold;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-6);
        }

        .content-subTitle {
          font-family: Poppins;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: #fafafa;
        }
      }
    }
  }
  .savings-container-secondPart {
    width: 70%;

    .form-left {
      width: 80%;
      height: fit-content;
      padding: 1rem;
      border-radius: 10px;
      box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
        -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
      background-color: var(--b-w-6);
      width: 100%;
      margin-bottom: 2rem;
    }

    .headerTabPrint {
      // TABLE
      width: 100%;
      display: flex;
      justify-content: center;
      cursor: pointer;
      .headerTabPrintItem {
        width: 12%;

        .arrow {
          width: 50px;
          height: 50px;
          margin: 20px auto 0px auto;
          border-radius: 10px;
          box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
            -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
        }
      }
      .headerTabPrintBigItem {
        width: 26%;
      }
    }

    .headerTabPrintItemSmallTitle {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      padding-bottom: 6px;
      color: var(--b-w-2);
    }

    .headerTabPrintItemMediumTitle {
      font-size: 14px !important;
    }

    .headerTabPrintItemBigTitle {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      color: var(--b-w-1);
      span {
        font-size: 16px;
        font-weight: bold;
      }
    }

    .notAvailable {
      font-weight: 300;
      color: var(--b-w-1);
    }

    .bodyTabPrint {
      padding: 0 12%;

      .grayLine {
        width: 100%;
        height: 1px;
        background-color: #ececec;
      }
    }

    .bodyTabPrintContent {
      display: grid;
      grid-template-columns: 20% 20% 20% 20% 20%;
      margin-top: 1rem;
      position: relative;
      .editIconContent {
        position: absolute;
        right: -45px;
        .editIcon {
          font-size: 18px;
          color: #1d3557;
          cursor: pointer;
        }
      }

      .bodyTabPrintElement {
        padding-bottom: 16px;
      }
    }

    .loadHistoryPayments {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 2rem 0;
    }

    .loadHistoryPaymentsHeader {
      padding: 0.5rem;
      background-color: #ececec;
      color: #777274;
      font-weight: bold;
      text-align: center;
      font-size: 12px;
      font-style: italic;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .mbx2 {
      margin-bottom: 1rem;
    }

    .loadHistoryPaymentsTitles {
      display: grid;
      grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr;
      padding: 1rem;
      background-color: #fafafa;
      color: #777274;
      font-weight: 500;
      text-align: center;
      font-size: 12px;
      font-style: italic;
      border: 1px solid #ececec;
    }

    .loadHistoryPaymentsBody {
      display: grid;
      grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr;
      padding: 1rem;
      background-color: white;
      color: black;
      font-weight: bold;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      font-style: italic;
      .greenText {
        color: #11b78c;
      }
    }

    .form-left-bord-black {
      border-left: 10px solid var(--primary-2);
    }

    .form-left-bord-blue {
      border-left: 10px solid var(--primary-1);
    }

    .form-left-bord-soft-blue {
      border-left: 10px solid var(--links-light);
    }

    .form-left-bord-red {
      border-left: 10px solid var(--danger-light);
    }

    .form-left-bord-orange {
      border-left: 10px solid var(--warning-light);
    }

    .form-left-bord-yellow {
      border-left: 10px solid var(--alert-light);
    }

    .form-left-bord-gray {
      border-left: 10px solid var(--b-w-3);
    }

    .form-left-bord-green {
      border-left: 10px solid var(--success-light);
    }

    .info-right {
      width: 20%;
      height: fit-content;
      margin: 0px 0px 0px 24px;
      padding: 24px 14px 21px 16px;
      border-radius: 10px;
      box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
        -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
      background-color: var(--b-w-6);

      .comment {
        display: block;
        margin: 16px 2px 13px 0;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: var(--b-w-2);
      }

      .border-option {
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--b-w-3);
      }

      .total-gain {
        margin: 19px 2px 10px 0;
        font-family: Poppins;
        font-size: 32px;
        font-weight: 300;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: var(--b-w-1);
      }

      .dot {
        height: 16px;
        width: 16px;
        margin: 16px 16px 0px 1px;
        border-radius: 50%;
        display: inline-block;
      }

      .dot-black {
        background-color: var(--primary-2);
      }

      .dot-blue {
        background-color: var(--primary-1);
      }

      .dot-softblue {
        background-color: var(--links-light);
      }

      .dot-red {
        background-color: var(--danger-light);
      }

      .dot-orange {
        background-color: var(--warning-light);
      }

      .dot-yellow {
        background-color: var(--alert-light);
      }

      .dot-green {
        background-color: var(--success-light);
      }
    }
  }

  .generalTitle {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #122745;
  }

  .actions-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.savings-container-resume {
  display: flex;
  flex-direction: column;
  padding: 4vh 8vh;
  width: 70%;
  margin-top: -8rem;
}

.saving-resume-white {
  width: 100%;

  .savings-resume {
    width: 100%;
    height: fit-content;
    padding: 24px 15px 32px 0;
    border-radius: 10px;
    box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5);
    background-color: var(--b-w-6);

    .form-message {
      margin: 0 0 16px;
      font-family: OpenSans;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.63;
      letter-spacing: normal;
      color: var(--primary-2);
    }

    .form-title {
      margin: 0 167px 34px 23px;
      font-family: OpenSans;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      color: #122745;
    }

    .chartRow {
      display: flex;
      justify-content: space-around;
      .chartItem {
        display: flex;
        flex-direction: column;

        .title {
          margin-top: 15px;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          color: var(--b-w-2);
          margin-bottom: 1rem;
          z-index: 2;
        }

        .title2 {
          margin-top: 15px;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          color: var(--b-w-2);
          margin-bottom: 0.5rem;
        }

        .total-gain {
          font-family: Poppins;
          font-size: 32px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          .currencySymbol {
            font-size: 24px;
          }
        }

        .chartDiv {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: -2em 0 -1em 0;
        }

        .chartText {
          font-family: Poppins;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          z-index: 2;
          .chartTextTop {
            font-weight: 500;
            color: #1d3557;
            .currencySymbol {
              font-size: 16px;
            }
            .topAmount {
              font-size: 24px;
            }
          }
          .chartTextSlash {
            font-size: 12px;
          }
          .chartTextBottom {
            font-size: 12px;
            color: #777274;
            .currencySymbol {
              font-size: 12px;
            }
          }
        }
      }
      .chartSubItem {
        display: flex;
        flex-direction: column;
      }
      .element {
        color: #777274;

        b {
          color: black;
        }
      }

      .dot {
        height: 12px;
        width: 12px;
        margin: 12px 12px 0px 0px;
        border-radius: 50%;
        display: inline-block;
      }

      .dot-black {
        background-color: var(--primary-2);
      }

      .dot-blue {
        background-color: var(--primary-1);
      }

      .dot-softblue {
        background-color: var(--links-light);
      }

      .dot-red {
        background-color: var(--danger-light);
      }

      .dot-orange {
        background-color: var(--warning-light);
      }

      .dot-yellow {
        background-color: var(--alert-light);
      }

      .dot-green {
        background-color: var(--success-light);
      }
    }

    .form-row {
      min-height: 50px;
      border-bottom: 1px solid var(--b-w-3);
      display: flex;
      justify-content: space-around;

      &:last-child {
        border-bottom: none;
      }

      .icon {
        margin: 40px 0px 0px 30px;
      }

      .off-item {
        width: 40%;
      }

      .pad-left {
        padding-left: 15%;
      }

      .item {
        max-width: 100%;
        display: flex;
        flex-direction: column;

        .value {
          font-weight: bold;

          .input-normal {
            width: 100%;
            margin: 8px 0px 0px 0px;
            padding: 9px 9px 8px 10px;
            border-radius: 5px;
            border: solid 1px var(--success-dark);
            background-color: var(--b-w-6);
          }

          .input-info {
            width: 90%;
            margin: 8px 0px 0px 0px;
            padding: 9px 9px 8px 10px;
            border-radius: 5px;
            border: solid 1px var(--b-w-4);
            background-color: var(--b-w-6);
          }

          .input-normal::placeholder {
            font-family: Poppins;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: italic;
            line-height: normal;
            letter-spacing: normal;
            color: var(--b-w-3);
          }
        }

        .title {
          margin-top: 15px;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          color: var(--b-w-2);
          margin-bottom: 1rem;
        }

        .chartText {
          font-family: Poppins;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          .chartTextTop {
            font-weight: 500;
            color: #1d3557;
            .currencySymbol {
              font-size: 16px;
            }
            .topAmount {
              font-size: 24px;
            }
          }
          .chartTextSlash {
            font-size: 12px;
          }
          .chartTextBottom {
            font-size: 12px;
            color: #777274;
          }
        }

        .sub-title {
          margin-top: 20px;
          font-family: OpenSans;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.63;
          letter-spacing: normal;
          color: var(--primary-2);
        }

        .comment {
          display: block;
          margin: 16px 2px 13px 0;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-2);
        }

        .border-option {
          margin-top: 20px;
          margin-bottom: 20px;
          border-bottom: 1px solid var(--b-w-3);
        }

        .aportaciones {
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: #122745;
        }

        .total-gain {
          margin: 19px 2px 10px 0;
          font-family: Poppins;
          font-size: 32px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-1);
        }

        .element {
          color: #777274;

          b {
            color: black;
          }
        }

        .dot {
          height: 16px;
          width: 16px;
          margin: 16px 16px 0px 1px;
          border-radius: 50%;
          display: inline-block;
        }

        .dot-black {
          background-color: var(--primary-2);
        }

        .dot-blue {
          background-color: var(--primary-1);
        }

        .dot-softblue {
          background-color: var(--links-light);
        }

        .dot-red {
          background-color: var(--danger-light);
        }

        .dot-orange {
          background-color: var(--warning-light);
        }

        .dot-yellow {
          background-color: var(--alert-light);
        }

        .dot-green {
          background-color: var(--success-light);
        }
      }
    }
  }
}

// BENEFICIARIES
.beneficiaries_content {
  display: flex;
  flex-direction: column;
  padding: 4vh 8vh;
  width: 70%;
  margin-top: 2rem;
  * {
    font-family: "Poppins";
  }
  .breadcrumbs_content {
    display: flex;
    .element {
      padding-right: 8px;
      p {
        font-size: 14px;
        color: #c6bfc2;
      }
      &:last-child {
        p {
          font-weight: 500;
          color: #1d3557;
        }
      }
    }
  }
  .beneficiariesTitle {
    font-size: 48px;
    font-weight: bold;
    font-stretch: normal;
    font-style: italic;
    color: #1d3557;
  }
  .backButton {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .twoBaseContent {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    .baseElement {
      width: 65%;
      border-radius: 10px;
      box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
        -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
      background-color: #fafafa;
      .contentbaseelement {
        padding: 1rem;
        .addBeneficiaryButton {
          width: 344px;
          height: 39px;
          margin-top: 1rem;
          padding: 8px 24px;
          border-radius: 5px;
          box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
            -5px -5px 8px 0 var(--b-w-6);
          background-color: #ffffff;
          line-height: normal;
          letter-spacing: 1px;
          color: #048aa8;
          font-family: Poppins;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 1px;
          border-color: white;
          border: 0;
        }
        .subtitleElement {
          font-size: 16px;
          color: #1d3557;
          font-weight: 300;
        }
      }
      &:last-child {
        width: 32%;
      }
    }
  }
  .lcontent {
    display: flex;
    .inputLabel {
      width: 50%;
      .inputBase {
        width: 80%;
        border: 1px solid #ececec;
        border-radius: 5px;
        padding: 6px;
        margin-top: 8px;
        background-color: #ffffff;
      }
      .mediumSize {
        width: 50% !important;
      }
      .twoElementsIL {
        display: flex;
        .deleteButtonContent {
          width: 35px;
          height: 35px;
          margin-left: 1rem;
          border-radius: 5px;
          box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
            -5px -5px 8px 0 #ffffff;
          background-color: #fafafa;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 6px;
          .deleteButton {
            font-size: 18px;
            color: #c5274d;
          }
        }
      }
    }
  }
  .smallLine {
    width: 30% !important;
  }
}
