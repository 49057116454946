.imageContent {
  position: absolute;
  top: 3rem;
  left: 35%;
}

.form-left-bord-soft-blue {
  border-left: 10px solid var(--links-light);
}

.form-left-bord-green {
  border-left: 10px solid #048aa8;
}
