.circular-spinner-container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.1);
}
