span.contentTitle {
  color: #fff;
}
span.MuiTypography-root.MuiFormControlLabel-label.Mui-disabled.MuiTypography-body1 {
  color: #777274;
}

.companiesAdd {
  * {
    font-family: Poppins;
  }
  display: flex;
  flex: 1;
  justify-content: center;

  color: #141414;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to top, white 97%, white) !important;
  .companiesHeroContent {
    width: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    background-image: linear-gradient(to top, black 97%, black);
    height: 410px;
  }
  .inputBase {
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 6px;
    margin-top: 1rem;
  }
  .changePic {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .companiesContainer {
    display: flex;
    flex-direction: column;
    width: 65vw;
    padding: 4vh 8vh;

    .breadcrumbs {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding-right: 18px;
        padding-left: 18px;
        border-right: 1px solid #fafafa;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        color: #fafafa;

        a {
          text-decoration: none;
          color: inherit;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .companiesHero {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: 600px;
    height: calc(60vh - 80px);
    justify-content: space-between;

    .content {
      padding: 4vh;
      padding-left: 0;

      .contentTitle {
        font-size: 48px;
        font-weight: bold;
        font-style: italic;
      }
      .subTitleContent {
        font-size: 32px;
        font-weight: 300;
        font-stretch: normal;
        font-style: italic;
        color: #fafafa;
        margin: 0;
      }
      .paragraphContent {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        color: #fafafa;
        span {
          font-weight: bold;
          font-style: italic;
        }
      }

      p {
        line-height: 200%;
        margin-bottom: 2rem;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        color: var(--b-w-5);
      }

      ul {
        padding: 0;
        list-style-position: inside;
        list-style-type: none;
        font-weight: 400;
      }

      li {
        padding-left: 1.3em;
      }

      li:before {
        content: "-"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        color: var(--success-dark);
      }

      .text-style-1 {
        font-weight: 500;
        font-style: italic;
      }
    }
  }
  .companiesContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
    .companiesContentData {
      width: 65vw;
      border-radius: 5px;
      margin-top: 0;
      margin-bottom: 3rem;
      display: flex;
      justify-content: space-between;
    }
  }
  .sideContent {
    width: calc(22% + 2rem);
  }
  .sideBarCompanies {
    background-color: white;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -2px 1px 8px 0 var(--b-w-6);
    border-radius: 15px;
    padding: 25px;
    .numOfCompanies {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      color: #777274;
    }
    .element {
      margin-top: 1rem;
    }
    .inputBase {
      width: 100%;
    }
  }
  .searchCompany {
    flex: 2;
    position: relative;
    border-radius: 5px;
    background-color: var(--b-w-6);

    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--b-w-1);
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5);
    margin-top: 1rem;

    &:hover {
      background-color: whitesmoke;
    }
  }

  .searchIconCompany {
    padding: 0 16px;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    color: var(--b-w-3);
    max-height: 800px;
    overflow: auto;
  }
  .gray_button {
    background-color: #fafafa;
    padding: 8px 24px;
    border-radius: 5px;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -5px -5px 8px 0 var(--b-w-6);
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    color: #777274;
    border: 1px solid #fafafa;
    text-transform: uppercase;
  }
  .greenColor {
    color: #11b78c !important;
  }
  .backButton {
    width: 65vw;
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    .white_button_bluecolor {
      padding: 8px 24px;
      border-radius: 5px;
      box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
        -5px -5px 8px 0 var(--b-w-6);
      background-color: #ffffff;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      color: #11b78c;
      border-color: transparent;
      text-transform: uppercase;
    }
  }
  .white_button {
    font-family: Poppins;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--primary-1);
    padding: 0.5rem 1rem;
    border-color: white;
    width: 210px;
    border-radius: 5px;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -5px -5px 8px 0 var(--b-w-6);
    background-color: var(--b-w-6);
    border-width: 0;
    height: 39px;
  }
  .blueButton {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -5px -5px 8px 0 var(--b-w-6);
    border: solid 1px #048aa8;
    background-color: #048aa8;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    width: 210px;
    height: 39px;
  }
  .grayLine {
    width: 100%;
    height: 1px;
    margin: 1.2rem 8px 1rem 0;
    background-color: #ececec;
  }
  .listOfCompanies {
    margin-top: 1rem;
  }
  .companyItem {
    display: flex;
    padding: 12px 0;
    align-items: center;
    cursor: pointer;
    .imageCompany {
      width: 40px;
      height: 40px;
      margin: 0 16px 0 0;
    }
    .nameCompany {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      color: #000000;
    }
  }
  .companyunselected {
    opacity: 0.3;
  }
  .companyselected {
    background-color: #ececec;
    border-radius: 10px;
    position: relative;
  }
  .pinkoval {
    width: 16px;
    height: 14px;
    background-color: #c5274d;
    border-radius: 50%;
    position: absolute;
    right: 1rem;
  }

  .principalContentCompany {
    background-color: white;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -2px 1px 8px 0 var(--b-w-6);
    width: 58%;
    border-radius: 15px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contall {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    width: calc(58% + 4rem);
  }
  .allwidth {
    width: calc(100% - 4rem);
    margin-top: 1rem;
  }
  .nopadding {
    margin-top: 0;
  }
  .noShadow {
    width: 100%;
    box-shadow: none;
    margin-top: 0;
    .searchCompany {
      width: 100%;
    }
  }
  .centerElements {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .savingsHead {
    display: flex;
    .savingsElement {
      width: calc(100% / 3);
    }
  }
  .loanOpt {
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-top: 2rem;
    .grayLine {
      margin-top: 10px;
    }
    .blackTextsmall {
      margin: 8px 0;
    }
  }
  .cancelledApp {
    width: calc(100% - 2rem);
    padding: 1rem;
    background-color: #febccc;
    margin-top: 1rem;
    .cancelled {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      padding: 3px 6px 4px;
      border-radius: 5px;
      background-color: #ef476f;
      width: 162px;
    }
  }
  .typeSaving {
    width: 100%;
    display: flex;
    margin-top: 1rem;
    .typeElement {
      &:first-child {
        width: 40%;
      }
      &:nth-child(2) {
        width: 60%;
      }
      .blackText {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
      }
    }
  }
  .savingTypeHeader {
    display: flex;
    .element {
      width: calc(100% / 3);
      .blackText {
        font-weight: 300;
        font-size: 24px;
      }
      &:first-child {
        .blackText {
          font-size: 16px;
        }
      }
    }
  }
  .allw {
    width: 100%;
  }
  .inlineElements {
    display: flex;
    flex-wrap: wrap;
    .element {
      width: calc(100% / 3);
      margin: 8px 0;
      &:first-child,
      &:nth-child(4) {
        width: 25%;
      }
    }
  }
  .payrollbody {
    .element {
      width: 40%;
    }
  }
  .loanheader {
    display: flex;
    .element {
      width: 60%;
      &:last-child {
        width: 40%;
      }
      .encursostatus {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        padding: 3px 6px 4px;
        border-radius: 5px;
        background-color: #4897d9;
        width: 55px;
        margin-top: 12px;
      }
    }
  }
  .loanbody {
    display: flex;
    .element {
      width: calc(100% / 3);
    }
  }
  .loanfooter {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    color: #0683a1;
  }
  .subtitleType {
    color: #000000;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
  }
  .savingTypeTableHeader,
  .savingTypeTableBody {
    display: flex;
    .element {
      width: calc(100% / 3);
      .blackText {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
      }
    }
  }
  .companiesHeader {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    .bigItem {
      width: 40% !important;
    }
    .item {
      width: calc(17% - 1rem);
      display: flex;
      align-items: center;
      &:first-child {
        width: calc(40% + 1rem);
      }
      &:nth-child(3) {
        width: calc(23% - 1rem);
      }
      &:nth-child(4) {
        width: calc(17% + 1rem);
      }
    }
    .grayDarkText {
      color: #777274;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      span {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
      }
      .greenText {
        color: #11b78c;
      }
    }
  }
  .companyDescription {
    width: 100%;
    border-radius: 5px;
    box-shadow: 6px 6px 16px 0 rgba(166, 171, 189, 0.5);
    background-color: #fff;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    cursor: pointer;
    .paddingContent {
      padding: 1.5rem;
      width: 100%;
      display: flex;
      .imageText {
        img {
          width: 70px;
          height: 65px;
        }
        p {
          margin: 0 0 0 1rem;
        }
      }
      .bigItem {
        width: 40% !important;
      }
      .item {
        width: 17%;
        display: flex;
        align-items: center;
        &:first-child {
          width: 40%;
        }
        &:nth-child(3) {
          width: 22%;
        }
        .itemCompanyName {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
        }
        .blackText {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;
          .smallText {
            font-size: 16px;
            font-weight: 500;
            margin-right: 8px;
            margin-top: 6px;
          }
          .greenText {
            color: #11b78c;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: italic;
            margin-left: 8px;
          }
        }
      }
      .activeStatus {
        padding: 4px 10px;
        border-radius: 5px;
        background-color: #11b78c;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        margin-left: 8px;
      }
      .arrowIcon {
        display: flex;
        align-items: center;
        color: #000;
      }
    }
  }
  .savings_title {
    color: #000;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
  }
  .tablehead {
    display: flex;
    .switchAndIcon {
      margin-left: auto;
      margin-right: 0;
    }
  }
  .sideOpt {
    width: 100%;
    .redContent {
      width: calc(100% - 16px);
      border-radius: 5px;
      background-color: rgba(239, 71, 111, 0.2);
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      color: #c5274d;
      padding: 13px;
      text-align: center;
    }
    .infoContent {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .white_button {
      margin: 1rem 0;
    }
    .twocolumns {
      width: 100%;
      display: flex;
      margin-top: 1rem;
      .element {
        width: 50%;
        .blackText {
          color: #000000;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
        }
      }
      .inputBase {
        width: 90%;
        margin-top: 1rem;
      }
      .checkText {
        display: flex;
        .blackText {
          margin-left: -4rem;
        }
      }
      .blueDarkColorT {
        color: #1d3557;
      }
      .blueMediumColorT {
        color: #048aa8;
      }
      .blueLightColorT {
        color: #6cb6f5;
      }
      .pinkColorT {
        color: #ef476f;
      }
      .orangeColorT {
        color: #fa7e58;
      }
      .yellowColorT {
        color: #f5a61d;
      }
      .greenColorT {
        color: #15db93;
      }
    }
    .threecolumns {
      width: 100%;
      display: flex;
      .element {
        width: calc(100% / 3);
      }
    }
  }
  .smallLine {
    width: 150px;
  }

  .twoElements {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .white_button,
    .blueButton {
      width: 48%;
    }
  }

  .deledEmployee {
    width: 100%;
    text-align: center;
    p {
      margin: 1rem 0;
      font-family: Poppins;
      font-size: 14px;
      color: #ef476f;
      font-weight: 500;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
  // COMPANY UNSELECTED
  .noCompanySelectedContent {
    padding: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #777274;
    .arrowIcons {
      transform: rotateY(180deg);
    }
    .textUnSelected {
      display: flex;
      align-items: center;
      p {
        font-size: 24px;
        padding: 0 0 0 2rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
      }
    }
    img {
      width: 400px;
    }
  }

  // Company selected
  .companyInfoContent {
    width: 100%;
    .infoContentHeader {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .iconName {
        display: flex;
        img {
          width: 70px;
          height: 65px;
        }
      }
      .companyNameContent {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 1rem;
        p {
          margin: 0;
        }
      }
      .infoIcons {
        color: #000000;
        svg {
          margin: 0 1rem;
        }
      }
    }
  }
  .companyName {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    color: #000000;
  }
  .navtabstable {
    display: flex;
    padding: 0 1rem;
    margin-top: 1.5rem;
    color: #1d3557;
    margin-bottom: 1.5rem;
    .navtab {
      padding: 0.5rem;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      cursor: pointer;
      opacity: 0.5;
    }
    .navtabselected {
      border-bottom: 3px solid #1d3557;
      opacity: 1;
    }
    .navtabsunelected {
      border-bottom: 1px solid transparent;
    }
  }
  // chart
  .graphicHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .option {
      min-width: 120px;
      .selectElement {
        width: 130px;
        margin-top: 6px;
      }
    }
  }
  .twoColumnsBase {
    display: flex;
    .oneColumn {
      width: 60%;
      &:last-child {
        width: 40%;
      }
    }
  }

  // white box
  .whiteShadowBox {
    padding: 16px;
    border-radius: 5px;
    box-shadow: 6px 6px 16px 0 rgba(166, 171, 189, 0.5);
    background-color: white;
    margin-top: 1rem;
  }
}
// input
.MuiInput-underline:before {
  border-color: transparent !important;
}
.MuiInput-underline:hover:not(.Muidisabled):before {
  border-bottom: 1px solid transparent !important;
}
.MuiFilledInput-underline:before {
  border-bottom: 0px !important;
}
.MuiFilledInput-underline:hover:before {
  border-bottom: 0px !important;
  background-color: transparent !important;
}
.MuiFilledInput-underline:after {
  border-bottom: 0px !important;
}
.MuiFilledInput-root {
  background-color: transparent !important;
  height: 18px !important;
}
.MuiFilledInput-input {
  padding: 0 !important;
  color: black !important;
  font-size: 12px !important;
  font-family: Poppins !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: italic !important;
}
.MuiFilledInput-root.Mui-focused {
  background-color: transparent !important;
}

// color

.grayText {
  color: #777274;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  margin: 0;
}
.blackText {
  color: #000;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  margin: 0;
  display: flex;
  align-items: center;
  margin-top: 6px;
  .smallText {
    font-size: 16px;
    font-weight: 500;
    margin-right: 8px;
    margin-top: 6px;
  }
  .greenText {
    color: #11b78c;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: italic;
    margin-left: 8px;
  }
}
.smallGrayText {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  color: #777274;
}
.marginTopbase {
  margin-top: 8px;
}
.bigSubtitle {
  margin: 0;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  color: #000;
}
.smallSubtitle {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  margin: 0;
}
.smallGrayLetter {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  color: #777274;
  margin: 0;
}
.smallBlackLetter {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  color: #000000;
  margin: 0;
}
.m-t-base {
  margin-top: 8px !important;
}
.m-t-basex2 {
  margin-top: 16px !important;
}
.blackTextsmall {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
}
.mediumText {
  font-size: 16px !important;
}
// Switch button
.MuiSwitch-thumb {
  background-color: #fafafa !important;
  box-shadow: 0 0 8px 0 #a6abbd !important;
}
.MuiSwitch-track {
  background-color: #a6abbd !important;
}
.MuiSwitch-colorSecondary.Mui-checked {
  color: #048aa8 !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #048aa8 !important;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  /*font-size: 1.25em;
        font-weight: 700;
        color: white;
        background-color: black;
        display: inline-block;*/
  cursor: pointer;
}

/*.inputfile:focus + label,
    .inputfile + label:hover {
        background-color: red;
    }*/

.error_input {
  width: 90%;
  border-radius: 5px;
  padding: 6px;
  margin-top: 1rem;
}
.error_input:focus {
  border: 2px solid red;
}
