/**
 * Navbar
 * =================================
 */

.header {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #1d3557;
}

.ahorros-subtitle {
  margin-top: 100px;
  color: #1d3557;
  margin-left: 100px;
  margin-right: 100px;
  font-family: Poppins;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: normal;
  text-align: center;
}

.nav {
  display: flex;
  align-items: center;
  width: 90%;
  height: 80px;
}

.nav__links {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  padding-top: 2 0px;
  a {
    margin: 4px 0 23px 60px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    color: #8d8d8d;
    text-decoration: none;
  }
  :hover {
    color: #fff;
  }

  .active {
    display: flex;
    align-items: center;
    color: #ffffff;
    height: 80px;
    border-bottom: 2px solid #ffffff;
  }
}

.main {
  width: 100%;
  display: flex;
  justify-content: center;
  height: 711px;
  background: linear-gradient(#1d3557, #15db93);
}

.main__slide {
  display: flex;
  justify-content: center;
  width: 90%;
  .hero-landing {
    width: 50%;
    padding: 0 70px;
    background-color: transparent;
    h1 {
      font-family: Poppins;
      font-size: 48px;
      font-weight: bold;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      margin: 0;
      padding-top: 40px;
    }
    p {
      font-family: Poppins;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
    }
    img {
      padding-top: 20px;
      max-width: none;
    }
  }
}

.ahorros {
  width: 100%;
  height: 950px;
  display: flex;
  flex-direction: column;
  padding-top: 100px;

  &__shape1 {
    padding: 90px 6%;
    position: absolute;
  }

  &__container {
    padding: 0 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;

    h1 {
      width: 915px;
      font-family: Poppins;
      font-size: 40px;
      font-weight: bold;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #1d3557;
    }

    img {
      padding-top: 50px;
    }

    p {
      padding-top: 90px;
      color: #1d3557;
      width: 917px;
      font-family: Poppins;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.33;
      letter-spacing: normal;
      text-align: center;
    }
  }

  &__shape2 {
    padding: 0 100px;
    position: absolute;
    padding-left: 50%;
    padding-top: 300px;
  }
}

/*
Section cards
*/

.beneficios {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 72px;

  &__container {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .item {
      width: 454px;
      height: 152px;
      margin: 72px 33px;
      .card {
        position: absolute;
        width: 443px;
        height: 152px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 10px 10px 10px 0 rgba(233, 233, 233, 0.9),
          -10px -10px 20px 0 rgba(255, 255, 255, 0.9),
          10px -10px 20px 0 rgba(233, 233, 233, 0.2),
          -10px 10px 20px 0 rgba(233, 233, 233, 0.2),
          inset -1px -1px 2px 0 rgba(233, 233, 233, 0.5),
          inset 1px 1px 2px 0 rgba(255, 255, 255, 0.3);

        .icon {
          display: flex;
          justify-content: center;
          margin-top: -58px;
        }

        h2 {
          font-family: Poppins;
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: #1d3557;
          padding-left: 32px;
          margin-top: -9px;
        }

        p {
          margin-top: -6px;
          color: #1d3557;
          padding-left: 32px;
          font-family: Poppins;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
        }
      }

      .integracion {
        border-left: 6px solid #048aa8;
      }

      .tasas {
        border-left: 6px solid #fa7e58;
      }

      .prestamos {
        border-left: 6px solid #f5a61d;
      }

      .multiples {
        border-left: 6px solid #6cb6f5;
      }

      .impulso {
        border-left: 6px solid #b658fa;
      }

      .ahorros-border {
        border-left: 6px solid #15db93;
      }
    }
  }
}

/*
Sabias que
*/

.known {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 110px;
  padding-top: 68px;
  flex-direction: column;

  &__container {
    width: 64%;

    .title {
      width: 100%;
      h1 {
        color: #8d8d8d;
        font-family: Poppins;
        font-size: 40px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin: 0;
      }
    }

    .row__line {
      width: 100%;
      display: flex;
      height: 72px;
      .box1 {
        width: 16%;
        border-right-style: dashed;
        border-color: #8d8d8d;
      }
      .box2 {
        width: 84%;
      }
    }

    .row__text {
      width: 100%;
      display: flex;

      .empty {
        width: 11%;
      }

      .number {
        color: #8d8d8d;
        width: 10%;
        height: 79px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Poppins;
        font-size: 48px;
        font-weight: bold;
      }

      .subtitle {
        width: 70%;
        padding-left: 32px;
        h2 {
          color: #4e4e4e;
          font-family: Poppins;
          font-size: 32px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          margin: 0;
        }
        p {
          font-family: Poppins;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: #4e4e4e;
        }
      }
    }
  }

  &__message {
    width: 64%;
    display: flex;
    justify-content: center;
    padding-top: 111px;

    p {
      text-align: center;
      width: 677px;
      color: #122745;
      font-family: Poppins;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }
}
