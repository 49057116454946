.form {
  width: 50%;
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form__login {
  display: flex;
  padding: 20px 32px;
  width: 450px;
  height: 452px;
  flex-direction: column;
  border-radius: 10px;
  z-index: 70;
  box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5);
  background-color: #fff;

  .MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-radius: 20px;
  }
  .form__title {
    margin: 0;
    padding: 15px 0;
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1d3557;
  }

  .form__group {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 18px;

    .form__label {
      margin-top: 8px;
      font-family: Poppins;
      font-style: italic;
      font-weight: bold;
      font-size: 14px;
      line-height: normal;
      color: #4e4e4e;
    }

    .input-info {
      width: 95%;
      border-radius: 20px;
      margin-top: 8px;
      padding: 10px;
      border: solid 1px #e5e5e5;
      background-color: #fff;
      font-family: Poppins;
      font-style: italic;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #8d8d8d;
      mix-blend-mode: normal;
    }
  }

  /* form actions check and recovery password*/
  .form_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .checkbox {
    display: none;
  }

  .label {
    position: relative;
    display: block;
    padding-left: 30px;
    user-select: none;
    margin: 30px 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4e4e4e;
  }
  .check-mark {
    width: 22px;
    height: 22px;
    background-color: #e9f1fa;
    border: 1px solid #048aa8;
    position: absolute;
    left: 0;
    display: inline-block;
    top: 0;
    border-radius: 50%;
  }
  .check-mark:hover {
    cursor: pointer;
  }
  .label .checkbox:checked + .check-mark {
    background-color: #66bb6a;
    transition: 0.1s;
    border: 1px solid #66bb6a;
  }
  .label .checkbox:checked + .check-mark:after {
    content: "";
    position: absolute;
    width: 10px;
    transition: 0.1s;
    height: 5px;
    background: #66bb6a;
    top: 45%;
    left: 50%;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .recover-button {
    text-align: right;
    font-family: Poppins;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    color: #048aa8;
    &:hover {
      cursor: pointer;
    }
  }
  .recover-button:hover {
    color: var(--links-light);
  }

  .login-inside,
  .btn-disabled {
    background: #048aa8;
    border: 1px solid #048aa8;
    box-sizing: border-box;
    padding: 10px 0 10px 0;
    margin-top: 1em;
    box-shadow: -5px -5px 8px #ffffff, 5px 5px 8px rgba(166, 171, 189, 0.5);
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1.14286px;
    text-transform: uppercase;
    color: #fafafa;
    border-radius: 50px;
  }
  .btn-disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.error_notification {
  color: red;
  font-size: 12px;
  padding-top: 4px;
}
