.modals {
  font-family: Poppins;
  .slots-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-wrap: wrap;
  }

  .slot {
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: relative;
    width: 124.22px;
    text-align: center;
    margin-bottom: 20px;
    &:hover {
      cursor: pointer;
      &:after {
        left: 0px;
        border-radius: 20px;
        width: 30px;
        height: 4px;
        margin-top: 4px;
        align-self: center;
        background-color: rgba(75, 105, 255, 0.8);
      }
    }
    &:after {
      content: "";
      display: flex;
      transition: all 0.5s;
      left: -20px;
    }
  }

  .slot.selected {
    &:after {
      content: "";
      display: flex;
      border-radius: 20px;
      width: 30px;
      height: 4px;
      margin-top: 4px;
      align-self: center;
      background-color: rgba(75, 105, 255, 0.8);
    }
  }

  .form-left {
    height: fit-content;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.2),
      -10px -10px 10px 0 rgba(255, 255, 255, 0.2);
    background-color: var(--b-w-6);
    margin-left: 10px;
    &:hover {
      align-items: center;
      border-top: 2px solid rgba(75, 105, 255, 0.8);
      border-bottom: 2px solid rgba(75, 105, 255, 0.8);
      border-right: 2px solid rgba(75, 105, 255, 0.8);
    }
  }

  .form-left-bord-black {
    border-left: 10px solid var(--primary-2);
    transition: all 0.2s;

    &:hover {
      align-items: center;
      cursor: pointer;
      border-top: 2px solid rgba(75, 105, 255, 0.8);
      border-bottom: 2px solid rgba(75, 105, 255, 0.8);
      border-right: 2px solid rgba(75, 105, 255, 0.8);
    }

    &.selected {
      padding-left: 10px;
      background-color: var(--primary-2);
      color: white;
    }
  }

  .form-left-bord-blue {
    border-left: 10px solid var(--primary-1);
    transition: all 0.2s;

    &:hover {
      align-items: center;
      cursor: pointer;
      border-top: 2px solid rgba(75, 105, 255, 0.8);
      border-bottom: 2px solid rgba(75, 105, 255, 0.8);
      border-right: 2px solid rgba(75, 105, 255, 0.8);
    }

    &.selected {
      padding-left: 10px;
      background-color: var(--primary-1);
      color: white;
    }
  }

  .form-left-bord-soft-blue {
    border-left: 10px solid var(--links-light);
    transition: all 0.2s;

    &:hover {
      align-items: center;
      cursor: pointer;
      border-top: 2px solid rgba(75, 105, 255, 0.8);
      border-bottom: 2px solid rgba(75, 105, 255, 0.8);
      border-right: 2px solid rgba(75, 105, 255, 0.8);
    }

    &.selected {
      padding-left: 10px;
      background-color: var(--links-light);
      color: white;
    }
  }

  .form-left-bord-red {
    border-left: 10px solid var(--danger-light);
    transition: all 0.2s;

    &:hover {
      align-items: center;
      cursor: pointer;
      border-top: 2px solid rgba(75, 105, 255, 0.8);
      border-bottom: 2px solid rgba(75, 105, 255, 0.8);
      border-right: 2px solid rgba(75, 105, 255, 0.8);
    }

    &.selected {
      padding-left: 10px;
      background-color: var(--danger-light);
      color: white;
    }
  }

  .form-left-bord-orange {
    border-left: 10px solid var(--warning-light);
    transition: all 0.2s;

    &:hover {
      align-items: center;
      cursor: pointer;
      border-top: 2px solid rgba(75, 105, 255, 0.8);
      border-bottom: 2px solid rgba(75, 105, 255, 0.8);
      border-right: 2px solid rgba(75, 105, 255, 0.8);
    }

    &.selected {
      padding-left: 10px;
      background-color: var(--warning-light);
      color: white;
    }
  }

  .form-left-bord-yellow {
    border-left: 10px solid var(--alert-light);
    transition: all 0.2s;

    &:hover {
      align-items: center;
      cursor: pointer;
      border-top: 2px solid rgba(75, 105, 255, 0.8);
      border-bottom: 2px solid rgba(75, 105, 255, 0.8);
      border-right: 2px solid rgba(75, 105, 255, 0.8);
    }

    &.selected {
      padding-left: 10px;
      background-color: var(--alert-light);
      color: white;
    }
  }

  .form-left-bord-gray {
    border-left: 10px solid var(--b-w-3);
    transition: all 0.2s;

    &:hover {
      align-items: center;
      cursor: pointer;
      border-top: 2px solid rgba(75, 105, 255, 0.8);
      border-bottom: 2px solid rgba(75, 105, 255, 0.8);
      border-right: 2px solid rgba(75, 105, 255, 0.8);
    }

    &.selected {
      padding-left: 10px;
      background-color: var(--b-w-3);
      color: white;
    }
  }

  .form-left-bord-green {
    border-left: 10px solid var(--success-light);
    transition: all 0.2s;

    &:hover {
      align-items: center;
      cursor: pointer;
      border-top: 2px solid rgba(75, 105, 255, 0.8);
      border-bottom: 2px solid rgba(75, 105, 255, 0.8);
      border-right: 2px solid rgba(75, 105, 255, 0.8);
    }

    &.selected {
      padding-left: 10px;
      background-color: var(--success-light);
      color: white;
    }
  }
}

.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
