.footer {
  display: flex;
  flex: 1;
  justify-content: center;
  background-color: #122745 !important;

  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  color: var(--b-w-6);

  .footerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
    padding: 4em 8vh;

    .logo {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: 1em;

      img {
        width: 60%;
      }

      span {
        padding-top: 2em;
        font-size: 12px;
      }
    }

    .links {
      display: flex;
      padding-top: 2em;

      a {
        padding: 0 1em 0 1em;
        color: #fff;
        text-decoration: none;
      }
    }
  }
}
