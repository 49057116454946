.bossEmployees {
  * {
    font-family: Poppins;
  }
  display: flex;
  flex: 1;
  justify-content: center;

  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to top, white 97%, white) !important;
  .companiesHeroContent {
    width: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    background: url("../../../assets/images/company/employeesHero.svg")
        no-repeat left -70px center,
      #048aa8 !important;
    height: 300px;
  }
  .companiesContainer {
    display: flex;
    flex-direction: column;
    width: 65vw;
    padding: 4vh 8vh;

    .breadcrumbs {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding-right: 18px;
        padding-left: 18px;
        border-right: 1px solid #fafafa;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        color: #fafafa;

        a {
          text-decoration: none;
          color: inherit;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .companiesHero {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: 600px;
    height: calc(60vh - 80px);
    justify-content: space-between;

    .content {
      padding: 4vh;
      padding-left: 0;

      .contentTitle {
        font-size: 48px;
        font-weight: bold;
        font-style: italic;
      }

      p {
        line-height: 200%;
        margin-bottom: 2rem;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        color: var(--b-w-5);
      }

      ul {
        padding: 0;
        list-style-position: inside;
        list-style-type: none;
        font-weight: 400;
      }

      li {
        padding-left: 1.3em;
      }

      li:before {
        content: "-"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        color: var(--success-dark);
      }

      .text-style-1 {
        font-weight: 500;
        font-style: italic;
      }
    }
  }
  .companiesContent {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #fafafa;
    .companiesContentData {
      width: 65vw;
      border-radius: 5px;
      margin-top: -5%;
      margin-bottom: 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .searchCompany {
    flex: 2;
    position: relative;
    border-radius: 5px;
    background-color: var(--b-w-6);

    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--b-w-1);
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5);
    margin-top: 1rem;
    width: 100%;
    &:hover {
      background-color: whitesmoke;
    }
  }

  .searchIconCompany {
    padding: 0 16px;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    color: var(--b-w-3);
    max-height: 800px;
    overflow: auto;
  }
  .white_button {
    font-family: Poppins;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--primary-1);
    padding: 0.5rem 1rem;
    border-color: white;
    width: 210px;
    border-radius: 5px;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -5px -5px 8px 0 var(--b-w-6);
    background-color: var(--b-w-6);
    border-width: 0;
    height: 39px;
  }
  .grayLine {
    width: 100%;
    height: 1px;
    margin: 1.2rem 8px 1rem 0;
    background-color: #ececec;
  }
  .bigLine {
    height: 2px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .listOfCompanies {
    margin-top: 1rem;
  }

  .principalContentCompany {
    background-color: white;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -2px 1px 8px 0 var(--b-w-6);
    width: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .savingsContent {
    width: calc(100% - 4rem);
    padding: 2rem;

    .savings_title {
      color: #000;
      font-size: 24px;
      font-weight: 300;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      margin: 0;
      width: 100%;
    }
    .grayText {
      color: #777274;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      margin: 0;
    }
    .tabHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .icon-container {
        padding: 10px;

        .icon-blue {
          float: right;
          color: var(--links-dark);
        }

        .icon-black {
          float: right;
          color: black;
          cursor: pointer;
        }
      }

      .tabItem-v2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .tabItem {
        width: 15.625%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        img {
          width: 75px;
          height: 60px;
        }

        &:first-child {
          width: 37.5%;
        }

        .companyName {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
        }

        .green-active {
          border-radius: 5px;
          background-color: var(--success-dark);
          width: fit-content;
          padding: 5px;
          color: white;
        }

        .gray-active {
          border-radius: 5px;
          background-color: var(--b-w-2);
          width: fit-content;
          padding: 5px;
          color: white;
        }

        .blackText {
          color: #000;
          font-family: Poppins;
          font-style: italic;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin: 0;
          display: flex;
          align-items: center;

          .smallText {
            font-size: 18px;
            font-weight: 500;
            margin-right: 8px;
            margin-top: 6px;
          }

          .greenText {
            color: #11b78c;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: italic;
            margin-left: 8px;
          }
        }
      }

      .searchCompany {
        flex: 2;
        position: relative;
        border-radius: 5px;
        background-color: var(--b-w-6);

        font-family: OpenSans;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--b-w-1);
        box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5);
        margin-top: 1rem;
        margin-bottom: 2rem;

        &:hover {
          background-color: whitesmoke;
        }

        .searchIconCompany {
          padding: 0 16px;
          height: 100%;
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 10;
          color: var(--b-w-3);
          max-height: 800px;
          overflow: auto;
        }
      }
      .disabled-employee {
        pointer-events: none;

        /* for "disabled" effect */
        opacity: 0.5;
        background: #ccc;
      }
    }

    .employee-row {
      position: relative;
      border-radius: 5px;
      background-color: var(--b-w-6);
      font-family: OpenSans;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: var(--b-w-1);
      box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5);
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      cursor: pointer;
    }

    .opacity-fade {
      opacity: 0.5;
    }
  }

  .savings-content-inside {
    width: 85%;
    margin: auto;

    .green-active {
      border-radius: 5px;
      background-color: var(--success-dark);
      width: fit-content;
      padding: 5px;
      color: white;
    }

    .gray-active {
      border-radius: 5px;
      background-color: var(--b-w-2);
      width: fit-content;
      padding: 5px;
      color: white;
    }
  }

  .totalComapniesTitle {
    width: 100%;
    color: #000;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    margin: 0;
  }

  .companiesHeader {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    .item {
      width: 14%;
      &:first-child {
        width: 30%;
      }
      &:nth-child(4) {
        width: 20%;
      }
    }
    .grayDarkText {
      color: #777274;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      span {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
      }
      .greenText {
        color: #11b78c;
      }
    }
  }
  .companyDescription {
    width: 100%;
    border-radius: 5px;
    box-shadow: 6px 6px 16px 0 rgba(166, 171, 189, 0.5);
    background-color: #fff;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    cursor: pointer;
    .paddingContent {
      padding: 1.5rem;
      width: 100%;
      display: flex;
      .imageText {
        img {
          width: 70px;
          height: 65px;
        }
        p {
          margin: 0 0 0 1rem;
        }
      }
      .item {
        width: 14%;
        display: flex;
        align-items: center;
        &:first-child {
          width: 30%;
        }
        &:nth-child(4) {
          width: 20%;
        }
        .itemCompanyName {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
        }
        .blackText {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;
          .smallText {
            font-size: 16px;
            font-weight: 500;
            margin-right: 8px;
            margin-top: 6px;
          }
          .greenText {
            color: #11b78c;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: italic;
            margin-left: 8px;
          }
        }
      }
      .arrowIcon {
        display: flex;
        align-items: center;
        color: #000;
        margin-left: 2rem;
      }
    }
  }
  .itemUnSelected {
    opacity: 0.6;
  }
  .companySelected {
    opacity: 1;
  }
  .companyUnSelected {
    background-color: rgba(166, 171, 189, 0.3);
  }
  // white box
  .whiteShadowBox {
    padding: 16px;
    border-radius: 5px;
    box-shadow: 6px 6px 16px 0 rgba(166, 171, 189, 0.5);
    background-color: white;
    margin-top: 1rem;
    .bigSubtitle {
      margin: 0;
      font-size: 24px;
      font-weight: 300;
      font-stretch: normal;
      font-style: italic;
      color: #000;
    }
  }
}
// MARGINS
.mt-basex2 {
  margin-top: 16px;
}
// Employees detail
.employeeMoreInfo {
  display: flex;
  flex-wrap: wrap;
  .element {
    width: 25%;
    margin-bottom: 1.5rem;
  }
}
.baseBoxContent {
  width: 100%;
  margin-top: 2rem;
}
.tableOptionsContent {
  display: flex;
  position: relative;
  .option {
    width: 25%;
    padding: 1.5rem 1rem;
    box-shadow: 6px 0 15px 0 rgba(166, 171, 189, 0.5);
    background-color: #ffffff;
    position: relative;
    cursor: pointer;
    .iconTitleOption {
      h2 {
        font-size: 24px;
        font-weight: 300;
        font-stretch: normal;
        font-style: italic;
        color: #000000;
        margin: 0;
      }
    }
    &:first-child {
      width: calc(25% - 6px);
      margin-left: 0;
      border-top-left-radius: 5px;
    }
    &:last-child {
      border-top-right-radius: 5px;
    }
    .bottomWhite {
      width: 99%;
      bottom: -13px;
      height: 1.6rem;
      left: 0;
      position: absolute;
      background-color: #ffffff;
      z-index: 2;
    }
  }
}
.baseWhiteBox {
  border-radius: 5px;
  box-shadow: 6px 6px 16px 0 rgba(166, 171, 189, 0.5);
  background-color: #ffffff;
  width: 100%;
  padding: 0 1rem;
  margin-top: 0;
  position: relative;
  z-index: 1;
  padding-bottom: 1rem;
}
.savingsChart {
  display: flex;
  justify-content: center;
  .form-message {
    margin: 0 0 16px;
    font-family: OpenSans;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    color: var(--primary-2);
  }

  .form-title {
    margin: 0 167px 34px 23px;
    font-family: OpenSans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #122745;
  }

  .form-row {
    width: 80%;
    min-height: 50px;
    display: flex;
    justify-content: space-around;

    &:last-child {
      border-bottom: none;
    }

    .icon {
      margin: 40px 0px 0px 30px;
    }

    .off-item {
      width: 38%;
      &:nth-child(3) {
        width: 24%;
        margin-top: 22px;
      }
    }

    .pad-left {
      padding-left: 15%;
    }

    .item {
      margin: 24px 24px 30px 0px;
      max-width: 100%;
      display: flex;
      flex-direction: column;

      .value {
        font-weight: bold;

        .input-normal {
          width: 100%;
          margin: 8px 0px 0px 0px;
          padding: 9px 9px 8px 10px;
          border-radius: 5px;
          border: solid 1px var(--success-dark);
          background-color: var(--b-w-6);
        }

        .input-info {
          width: 90%;
          margin: 8px 0px 0px 0px;
          padding: 9px 9px 8px 10px;
          border-radius: 5px;
          border: solid 1px var(--b-w-4);
          background-color: var(--b-w-6);
        }

        .input-normal::placeholder {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          color: var(--b-w-3);
        }
      }

      .title {
        margin-top: 1rem;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        color: var(--b-w-2);
      }

      .sub-title {
        margin-top: 20px;
        font-family: OpenSans;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: var(--primary-2);
      }

      .comment {
        display: block;
        margin: 16px 2px 13px 0;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: var(--b-w-2);
      }

      .border-option {
        margin-top: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--b-w-3);
      }

      .aportaciones {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: #122745;
      }

      .total-gain {
        margin: 19px 2px 10px 0;
        font-family: Poppins;
        font-size: 32px;
        font-weight: 300;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: var(--b-w-1);
      }

      .element {
        color: #777274;

        b {
          color: black;
        }
      }

      .dot {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        display: inline-block;
      }

      .dot-black {
        background-color: var(--primary-2);
      }

      .dot-blue {
        background-color: var(--primary-1);
      }

      .dot-softblue {
        background-color: var(--links-light);
      }

      .dot-red {
        background-color: var(--danger-light);
      }

      .dot-orange {
        background-color: var(--warning-light);
      }

      .dot-yellow {
        background-color: var(--alert-light);
      }

      .dot-green {
        background-color: var(--success-light);
      }
    }
    .nomargin {
      margin: 0;
      .total-gain {
        margin: 0px 2px 10px 0;
      }
    }
  }
}
.paddingSpace {
  display: flex;
  justify-content: center;
  .grayLine {
    width: 85%;
    height: 2px;
  }
}
.moreInfoSaving {
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 24px;
  .option {
    width: 38%;
    margin-top: 1rem;
    &:nth-child(3),
    &:nth-child(6) {
      width: 24%;
    }
  }
}
.typeOfSaving {
  .form-left {
    width: 100%;
    height: fit-content;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
      -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
    background-color: var(--b-w-6);
    width: 100%;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1.5rem;
  }

  .headerTabPrint {
    // TABLE
    width: 80%;
    display: flex;
    cursor: pointer;
    .headerTabPrintItem {
      width: 25%;
      margin-top: 1rem;

      .arrow {
        width: 50px;
        height: 50px;
        margin: 20px auto 0px auto;
        border-radius: 10px;
        box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
          -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
      }
    }
    .headerItemTopTab {
      width: calc(100% / 4);
      margin-top: 1rem;
    }
    .headerTabPrintBigItem {
      width: 26%;
    }
  }

  .headerTabPrintItemSmallTitle {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: var(--b-w-2);
  }

  .headerTabPrintItemMediumTitle {
    font-size: 14px !important;
  }

  .headerTabPrintItemBigTitle {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: var(--b-w-1);
    span {
      font-size: 12px;
      font-weight: bold;
    }
  }
  .smallText {
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  .notAvailable {
    font-weight: 300;
    color: var(--b-w-3);
  }

  .bodyTabPrint {
    width: 80%;

    .grayLine {
      width: 100%;
      height: 1px;
      background-color: #ececec;
    }
  }

  .bodyTabPrintContent {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 1rem;

    .bodyTabPrintElement {
      width: 25%;
    }
  }

  .loadHistoryPayments {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
  }

  .loadHistoryPaymentsHeader {
    padding: 1rem;
    background-color: #ececec;
    color: #777274;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    font-style: italic;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .loadHistoryPaymentsTitles {
    padding: 1rem;
    background-color: #fafafa;
    color: #777274;
    font-weight: 500;
    display: flex;
    text-align: center;
    font-size: 12px;
    font-style: italic;
    border: 1px solid #ececec;

    .loadHistoryPaymentsTitle {
      width: 15%;
      &:nth-child(2) {
        width: 25%;
      }
    }
  }

  .loadHistoryPaymentsBody {
    padding: 1rem;
    background-color: white;
    color: black;
    font-weight: bold;
    display: flex;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    font-style: italic;

    .loadHistoryPaymentsItem {
      width: 15%;
      &:nth-child(2) {
        width: 25%;
      }
    }

    .greenText {
      color: #11b78c;
    }
  }

  .form-left-bord-black {
    border-left: 10px solid var(--primary-2);
  }

  .form-left-bord-blue {
    border-left: 10px solid var(--primary-1);
  }

  .form-left-bord-soft-blue {
    border-left: 10px solid var(--links-light);
  }

  .form-left-bord-red {
    border-left: 10px solid var(--danger-light);
  }

  .form-left-bord-orange {
    border-left: 10px solid var(--warning-light);
  }

  .form-left-bord-yellow {
    border-left: 10px solid var(--alert-light);
  }

  .form-left-bord-gray {
    border-left: 10px solid var(--b-w-3);
  }

  .form-left-bord-green {
    border-left: 10px solid var(--success-light);
  }

  .info-right {
    width: 20%;
    height: fit-content;
    margin: 0px 0px 0px 24px;
    padding: 24px 14px 21px 16px;
    border-radius: 10px;
    box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
      -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
    background-color: var(--b-w-6);

    .comment {
      display: block;
      margin: 16px 2px 13px 0;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      color: var(--b-w-2);
    }

    .border-option {
      margin-top: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid var(--b-w-3);
    }

    .total-gain {
      margin: 19px 2px 10px 0;
      font-family: Poppins;
      font-size: 32px;
      font-weight: 300;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      color: var(--b-w-1);
    }

    .dot {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      display: inline-block;
    }

    .dot-black {
      background-color: var(--primary-2);
    }

    .dot-blue {
      background-color: var(--primary-1);
    }

    .dot-softblue {
      background-color: var(--links-light);
    }

    .dot-red {
      background-color: var(--danger-light);
    }

    .dot-orange {
      background-color: var(--warning-light);
    }

    .dot-yellow {
      background-color: var(--alert-light);
    }

    .dot-green {
      background-color: var(--success-light);
    }
  }
}
.twoColumnsInfo {
  width: 80%;
  margin-top: 2.5rem;
  display: flex;
  justify-content: space-between;
  .firstElementInfo {
    display: flex;
    .grayText {
      margin-right: 1rem;
      margin-top: 1px;
    }
  }
}
.showMoreInfoSaving {
  p {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    color: #0683a1;
    cursor: pointer;
  }
}
.simpletablecontent {
  width: 80%;
  display: flex;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  .option {
    margin-right: 2.5rem;
  }
}

//TEXT
// TEXT COLOR

.blueCText {
  color: #4897d9;
}
.greenCText {
  color: #11b78c;
}
.pinkCText {
  color: #c5274d;
}

// SPACE
.mbx4 {
  margin-bottom: 2rem;
}
.mlx10 {
  margin-left: 5rem !important;
}

.requests_content {
  width: calc(100% + 2rem);
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
  .requests_title {
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    color: #000000;
  }
}
.requests_c {
  display: flex;
  .alignCenter {
    display: flex;
    align-items: center;
  }
  .elementWidth {
    width: 17%;
  }
  .elementWidth2 {
    width: 22%;
  }
  .element {
    margin-right: 2rem;
    margin-top: 2rem;
    margin-bottom: 8px;
    margin-left: 1rem;
    .pinkCircle {
      width: 16px;
      height: 16px;
      background-color: #ef476f;
      border-radius: 50%;
    }
    .ad_request {
      border-radius: 5px;
      background-color: rgba(4, 138, 168, 0.2);
      padding: 4px 10px;
      margin-top: 8px;
      p {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        color: #048aa8;
      }
    }
    .loan_request {
      border-radius: 5px;
      background-color: rgba(129, 47, 229, 0.2);
      padding: 4px 10px;
      margin-top: 12px;
      p {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        color: #812fe5;
      }
    }
    .paidState {
      border-radius: 5px;
      background-color: #11b78c;
      padding: 4px 10px;
      margin-top: 12px;
      p {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        color: #ffffff;
      }
    }
    .rejectedState {
      border-radius: 5px;
      background-color: #ef476f;
      padding: 4px 10px;
      margin-top: 12px;
      p {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        color: #ffffff;
      }
    }
    .inCourseState {
      border-radius: 5px;
      background-color: #000000;
      padding: 4px 10px;
      margin-top: 12px;
      width: 60px;
      p {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        color: #ffffff;
      }
    }
  }
}
.opacityMedium {
  opacity: 0.8;
}
.icons_c {
  display: flex;
  position: absolute;
  right: 2rem;
  top: 45%;
  .icon {
    font-size: 20px;
    margin: 0 1rem;
    font-weight: bold;
    cursor: pointer;
  }
  .checkIcon {
    color: #11b78c;
  }
  .clearIcon {
    color: #ef476f;
  }
}
.loan_history_c {
  width: calc(100% - 8rem);
  margin: 0 4rem;
  .threeColumnsh {
    display: flex;
    .element {
      margin-left: 2rem;
    }
  }
  .typeOfSaving {
    display: flex;
    justify-content: center;
    .bodyTabPrint {
      width: 98%;
    }
  }
}

// ARROW
.arrowIcon_c {
  position: absolute;
  top: 3.5rem;
  right: 2rem;
  .arrowIconB {
    font-size: 22px;
    color: #000000;
  }
}

// MODALS
.modal-box-images {
  margin: 140px -32px -24px;
  .box-button {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .gray_button {
      margin: 31px 6px 0;
      min-width: 200px;
      font-weight: 600;
    }

    .green-button {
      margin: 31px 6px 0;
      padding: 8px 24px;
      border-radius: 5px;
      box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
        -5px -5px 8px 0 var(--b-w-6);
      background-color: var(--b-w-6);
      border: 0;
      min-width: 200px;
    }

    .green-text {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #11b78c;
    }
    .redText {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 1px;
      color: #ef476f;
    }
  }
}
.modalTitle {
  text-align: center;
  font-size: 24px;
  color: #1d3557;
  margin-top: 4rem;
  font-style: normal;
}
.dataElement {
  text-align: left;
}

#file-csv {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 4px;
  color: var(--primary-1);
  border-radius: 5px;
  background-color: var(--b-w-6);
  border-width: 0;
  height: 39px;
}

#upload-photo {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
