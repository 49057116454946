.button-back {
  &:hover {
    background-color: #122745;
    color: white;
  }
}

.employee-item-container {
  + .chevron-icon {
    transform: translateX(0px);
    transition: transform 0.2s ease-in;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(232, 222, 222, 0.523);
    .chevron-icon {
      transform: translateX(20px);

      transition: transform 0.2s ease-in;
    }
  }
}
