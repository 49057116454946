.period-row {
  width: 100%;
}
.period-item {
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid rgb(173, 173, 173);
  color: rgb(48, 48, 48);
}

.period-item div:first-child {
  margin-left: 5px;
  width: 15%;
}
.period-item div:nth-child(2) {
  width: 15%;
}
.period-item div:nth-child(3) {
  width: 15%;
}
.period-item div:nth-child(4) {
  width: 15%;
}
.period-item div:nth-child(5) {
  width: 15%;
}

.period-item div:last-child {
  width: 20%;
}
