.loan-form-container {
  width: 100%;
  position: absolute;
  z-index: 140;
  background-color: #f8faf9;
  animation: moveUp 1s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calcular-button {
  &:hover {
    background-color: var(--primary-2);
    color: white;
  }
}
.loan-form {
  padding-top: 2%;
  width: 80%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  &__header {
    width: 80%;
    display: flex;
  }

  &__left {
    width: 50%;
    display: flex;
    flex-direction: column;

    p {
      padding-top: 8%;
    }

    input {
      border: none;
      border-bottom: 2px solid gray;
      width: 250px;
    }
  }

  &__right {
    width: 50%;
    display: flex;
    justify-content: center;
  }

  &__circle {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 15px solid #00a877;
  }

  &__circle-warning {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    border: 15px solid #cf0000;
    span {
      margin-top: -20px;
    }
    h2 {
      color: #cf0000;
    }
  }

  &__select-group {
    width: 80%;
    .plazo {
      padding-bottom: 10px;
    }
    .aval {
      padding-top: 25px;
      padding-bottom: 10px;
    }
  }

  &__calculate_loan {
    padding-top: 3%;
    width: 80%;
    display: flex;
    justify-content: flex-end;
  }

  &__card {
    margin-top: 3%;
    width: 80%;
    background-color: #ffffff;
    border-radius: 10px;
    padding-bottom: 20px;

    h1 {
      margin: 25px;
      color: #525252;
    }

    .card__row {
      width: 100%;
    }

    .card__item {
      display: flex;
      align-items: center;
      height: 50px;
      color: rgb(31, 30, 30);
      p {
        font-weight: 600;
      }
    }

    .card__item div:first-child {
      margin-left: 20px;
      width: 25%;
    }

    .card__item div:nth-child(2) {
      width: 15%;
    }
    .card__item div:nth-child(3) {
      width: 30%;
    }

    .card__item div:nth-child(4) {
      width: 10%;
    }
    .card__item div:last-child {
      width: 10%;
    }
  }

  &__table-container {
    margin-top: 3%;
    padding-bottom: 3%;
    width: 80%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-bottom: 2%;
    border-radius: 10px;
  }

  &__table {
    padding: 0 2%;
    width: 100%;

    .table__head-row {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid rgb(160, 159, 159);
    }
    .table__head-row span {
      font-weight: 600;
      color: black;
    }

    .table__head-row span:first-child {
      width: 15%;
    }

    .table__head-row span:nth-child(2) {
      width: 15%;
    }
    .table__head-row span:nth-child(3) {
      width: 15%;
    }

    .table__head-row span:nth-child(4) {
      width: 15%;
    }

    .table__head-row span:nth-child(5) {
      width: 15%;
    }

    .table__head-row span:last-child {
      width: 20%;
    }
  }
}
