:root {
  --alert-dark: #d88a02;
  --b-w-1: #000000;
  --danger-dark: #c5274d;
  --warning-light: #fa7e58;
  --links-light: #6cb6f5;
  --b-w-4: #ececec;
  --success-dark: #11b78c;
  --links-dark: #4897d9;
  --danger-light: #ef476f;
  --b-w-3: #c4bfc1;
  --warning-dark: #e55a2f;
  --b-w-2: #777274;
  --alert-light: #f5a61d;
  --b-w-6: #ffffff;
  --success-light: #15db93;
  --primary-1: #048aa8;
  --primary-2: #1d3557;
  --b-w-5: #fafafa;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: Poppins;
  }
  font-family: "Open Sans", sans-serif;
}

// Animations
@keyframes moveLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveDown {
  0% {
    opacity: 0;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveUp {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
