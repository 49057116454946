p {
  margin: 0;
}

.companies-left {
  min-height: 470px;
}

.item-active {
  background-color: #122745;
  color: white;
}

.inner-content {
  max-width: 1200px;
}

.companies {
  .company-item {
    &:hover {
      cursor: pointer;
      background-color: #122745;
      color: white;
    }
  }

  * {
    font-family: Poppins;
  }
  display: flex;
  flex: 1;
  justify-content: center;

  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to top, white 97%, white) !important;

  .companiesHeroContent {
    width: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    background-image: linear-gradient(to top, black 97%, black);
    height: 300px;
  }
  .companiesContainer {
    display: flex;
    flex-direction: column;
    width: 65vw;
    padding: 4vh 8vh;

    .breadcrumbs {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding-right: 18px;
        padding-left: 18px;
        border-right: 1px solid #fafafa;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        color: #fafafa;

        a {
          text-decoration: none;
          color: inherit;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }
  .companiesHero {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: 600px;
    height: calc(60vh - 80px);
    justify-content: space-between;

    .content {
      padding: 4vh;
      padding-left: 0;

      .contentTitle {
        font-size: 48px;
        font-weight: bold;
        font-style: italic;
      }

      p {
        line-height: 200%;
        margin-bottom: 2rem;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        color: var(--b-w-5);
      }

      ul {
        padding: 0;
        list-style-position: inside;
        list-style-type: none;
        font-weight: 400;
      }

      li {
        padding-left: 1.3em;
      }

      li:before {
        content: "-"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        color: var(--success-dark);
      }

      .text-style-1 {
        font-weight: 500;
        font-style: italic;
      }
    }
  }
  .companiesContent {
    width: 100%;
    display: flex;
    justify-content: center;
    .companiesContentData {
      width: 65vw;
      border-radius: 5px;
      margin-top: -5%;
      margin-bottom: 3rem;
      display: flex;
      justify-content: space-between;
    }
  }
  .sideBarCompanies {
    background-color: white;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -2px 1px 8px 0 var(--b-w-6);
    width: 22%;
    height: 500px;
    overflow: hidden;
    border-radius: 15px;
    padding: 2rem;
    .numOfCompanies {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      color: #777274;
    }
  }
  .searchCompany {
    flex: 2;
    position: relative;
    border-radius: 5px;
    background-color: var(--b-w-6);

    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--b-w-1);
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5);
    margin-top: 1rem;

    &:hover {
      background-color: whitesmoke;
    }
  }

  .searchIconCompany {
    padding: 0 16px;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    color: var(--b-w-3);
    max-height: 800px;
    overflow: auto;
  }

  .white_button {
    font-family: Poppins;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--primary-1);
    padding: 0.5rem 1rem;
    border-color: white;
    width: 210px;
    border-radius: 5px;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -5px -5px 8px 0 var(--b-w-6);
    background-color: var(--b-w-6);
    border-width: 0;
    height: 39px;
  }

  .listOfCompanies {
    margin-top: 1rem;
    overflow: auto;
    height: 360px;
  }

  .companyItem {
    display: flex;
    padding: 12px 0;
    align-items: center;
    cursor: pointer;
    .imageCompany {
      width: 40px;
      height: 40px;
      margin: 0 16px 0 0;
    }
    .nameCompany {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      color: #000000;
    }
  }

  .companyunselected {
    opacity: 0.3;
  }

  .companyselected {
    background-color: #ececec;
    border-radius: 10px;
    position: relative;
  }

  .pinkoval {
    width: 16px;
    height: 14px;
    background-color: #c5274d;
    border-radius: 50%;
    position: absolute;
    right: 1rem;
  }

  .principalContentCompany {
    background-color: white;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -2px 1px 8px 0 var(--b-w-6);
    width: 58%;
    border-radius: 15px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .allwidth {
    width: calc(100% - 4rem);
    margin-top: 1rem;
  }

  .noShadow {
    width: 100%;
    box-shadow: none;
    margin-top: 0;
    .searchCompany {
      width: 100%;
    }
  }

  .savingsHead {
    display: flex;
    .savingsElement {
      width: calc(100% / 3);
    }
  }

  .loanOpt {
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-top: 2rem;
    .grayLine {
      margin-top: 10px;
    }
    .blackTextsmall {
      margin: 8px 0;
    }
  }

  .cancelledApp {
    width: calc(100% - 2rem);
    padding: 1rem;
    background-color: #febccc;
    margin-top: 1rem;
    cursor: pointer;
    .cancelled_c {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .iconArrow {
        font-size: 14px;
        color: #000000;
      }
    }
    .cancelled {
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      padding: 3px 6px 4px;
      border-radius: 5px;
      background-color: #ef476f;
      width: 162px;
    }
  }

  .typeSaving {
    width: 100%;
    display: flex;
    margin-top: 1rem;
    .typeElement {
      &:first-child {
        width: 40%;
      }
      &:nth-child(2) {
        width: 60%;
      }
      .blackText {
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
      }
    }
  }

  .savingTypeHeader {
    display: flex;
    .element {
      width: calc(100% / 3);
      .blackText {
        font-weight: 300;
        font-size: 24px;
      }
      .smallText {
        font-size: 16px;
      }
      //&:first-child {
      //.blackText {
      // font-size: 16px;
      //}
      //}
    }
  }

  .allw {
    width: 100%;
  }

  .inlineElements {
    display: flex;
    flex-wrap: wrap;
    .element {
      width: calc(100% / 3);
      margin: 8px 0;
      &:first-child,
      &:nth-child(4) {
        width: 25%;
      }
    }
  }

  .payrollbody {
    .element {
      width: 40%;
    }
  }

  .blackTextsmall {
    color: #000000;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
  }
  .mediumGrayColor {
    color: #c4bfc1 !important;
  }
  .txt14px {
    font-size: 14px !important;
  }
  .blueDarkText {
    color: #122745 !important;
  }
  .weight300 {
    font-weight: 300 !important;
  }
  .weight500 {
    font-weight: 500 !important;
  }

  .loanheader {
    display: flex;
    .element {
      width: 60%;
      &:last-child {
        width: 40%;
      }
      .encursostatus {
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        padding: 3px 6px 4px;
        border-radius: 5px;
        background-color: #4897d9;
        width: 55px;
        margin-top: 12px;
      }
    }
  }

  .loanbody {
    display: flex;
    .element {
      width: calc(100% / 3);
    }
  }

  .loanfooter {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    color: #0683a1;
  }

  .subtitleType {
    color: #000000;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
  }

  .savingTypeTableHeader,
  .savingTypeTableBody {
    display: flex;
    .element {
      width: calc(100% / 3);
      .blackText {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
      }
    }
  }

  .companiesHeader {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    .item {
      width: calc(17% - 1rem);
      display: flex;
      align-items: center;
      &:first-child {
        width: calc(40% + 1rem);
      }
      &:nth-child(3) {
        width: calc(23% - 1rem);
      }
      &:nth-child(4) {
        width: calc(17% + 1rem);
      }
    }
    .grayDarkText {
      color: #777274;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      span {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
      }
      .greenText {
        color: #11b78c;
      }
    }
  }

  .companyDescription {
    width: 100%;
    border-radius: 5px;
    box-shadow: 6px 6px 16px 0 rgba(166, 171, 189, 0.5);
    background-color: #fff;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    cursor: pointer;
    .paddingContent {
      padding: 1.5rem;
      width: 100%;
      display: flex;
      .imageText {
        img {
          width: 70px;
          height: 65px;
        }
        p {
          margin: 0 0 0 1rem;
        }
      }
      .item {
        width: 17%;
        display: flex;
        align-items: center;
        &:first-child {
          width: 40%;
        }
        &:nth-child(3) {
          width: 22%;
        }
        .itemCompanyName {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
        }
        .blackText {
          color: #000;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;
          .smallText {
            font-size: 16px;
            font-weight: 500;
            margin-right: 8px;
            margin-top: 6px;
          }
          .greenText {
            color: #11b78c;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: italic;
            margin-left: 8px;
          }
        }
      }
      .activeStatus {
        padding: 4px 10px;
        border-radius: 5px;
        background-color: #11b78c;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        margin-left: 8px;
      }
      .arrowIcon {
        display: flex;
        align-items: center;
        color: #000;
      }
    }
  }

  .savings_title {
    color: #000;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
  }

  .sideOpt {
    width: 100%;
    .redContent {
      width: calc(100% - 16px);
      border-radius: 5px;
      background-color: rgba(239, 71, 111, 0.2);
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      color: #c5274d;
      padding: 13px;
      text-align: center;
    }
    .infoContent {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .white_button {
      cursor: pointer;
      margin: 1rem 0;
    }
    .twocolumns {
      width: 100%;
      display: flex;
      .element {
        width: 50%;
      }
    }
    .threecolumns {
      width: 100%;
      display: flex;
      .element {
        width: calc(100% / 3);
      }
      .elementDis {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 8px;
        .blackTextsmall {
          margin-bottom: 8px;
        }
      }
    }
  }

  // COMPANY UNSELECTED
  .noCompanySelectedContent {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #777274;
    .arrowIcons {
      transform: rotateY(180deg);
    }
    .textUnSelected {
      display: flex;
      align-items: center;
      p {
        font-size: 24px;
        padding: 0 0 0 2rem;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
      }
    }
    img {
      width: 400px;
    }
  }

  // Company selected
  .companyInfoContent {
    width: 100%;
    padding: 1rem 0;
    .infoContentHeader {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .iconName {
        display: flex;
        img {
          width: 70px;
          height: 65px;
        }
      }
      .companyNameContent {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 1rem;
        p {
          margin: 0;
        }
      }
      .infoIcons {
        color: #000000;
        svg {
          margin: 0 1rem;
        }
      }
    }
  }
  .smallGrayText {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    color: #777274;
  }
  .companyName {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    color: #000000;
  }
  // chart
  .graphicHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .option {
      min-width: 120px;
      .selectElement {
        width: 130px;
        margin-top: 6px;
      }
    }
  }
  // color

  .grayText {
    color: #777274;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    margin: 0;
  }
  .blackText {
    color: #000;
    font-size: 24px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    margin: 0;
    display: flex;
    align-items: center;
    margin-top: 6px;
    .smallText {
      font-size: 16px;
      font-weight: 500;
      margin-right: 8px;
      margin-top: 6px;
    }
    .greenText {
      color: #11b78c;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: italic;
      margin-left: 8px;
    }
  }
  .greenTextColor {
    color: #11b78c !important;
  }
  .f-s11 {
    font-size: 11px !important;
  }
  .marginTopbase {
    margin-top: 8px;
  }
  .startAlign {
    text-align: left;
  }

  // white box
  .whiteShadowBox {
    padding: 16px;
    border-radius: 5px;
    box-shadow: 6px 6px 16px 0 rgba(166, 171, 189, 0.5);
    background-color: white;
    margin-top: 1rem;
    .bigSubtitle {
      margin: 0;
      font-size: 24px;
      font-weight: 300;
      font-stretch: normal;
      font-style: italic;
      color: #000;
    }
  }
}

// EMPLOYEE SELECTED
.allwidthNoPadding {
  width: 100% !important;
  margin-top: 1rem;
}
.topBottomPadding {
  padding: 1rem 0 !important;
}
.l_r_padding {
  padding: 0 1rem;
}
.contentInfo {
  display: flex;
  .element {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:last-child {
      width: 20%;
    }
  }
}
.blueBottomText {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #0683a1;
  cursor: pointer;
}
.appCancelled {
  padding: 1rem;
  background-color: #febccc;
  margin-top: 1rem;
  .text_and_icon {
    display: flex;
    justify-content: space-between;
    .arrowIcon {
      color: #000000;
    }
  }
  .cancelled {
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    padding: 3px 6px 4px;
    border-radius: 5px;
    background-color: #ef476f;
    width: 162px;
  }
}

.threecolumns {
  width: 100%;
  display: flex;
  .element {
    width: calc(100% / 3);
  }
  .elementDis {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 8px;
    .blackTextsmall {
      margin-bottom: 8px;
    }
  }
}

.inlineEl {
  display: flex;
  align-items: center;
  .element {
    width: calc(100% / 3);
    margin-top: 1rem;
    .grayText {
      margin-bottom: 6px;
    }
    .blackTextsmall {
      font-size: 14px;
      margin: 0;
    }
  }
  //.elementInTwo {
  //}
}
.benData {
  .blackTextsmall {
    font-size: 14px;
    margin: 8px 0 0 0;
  }
}

// GRAY TABLE
.grayTableContent {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.grayTableHeader {
  padding: 1rem;
  background-color: #ececec;
  color: #777274;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.grayTableTitles {
  padding: 1rem;
  background-color: #fafafa;
  color: #777274;
  font-weight: 500;
  display: flex;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  border: 1px solid #ececec;
  .grayTableTitle {
    width: calc(100% / 6);
    &:first-child {
      width: calc(100% / 7);
    }
    &:last-child {
      width: calc(100% / 5);
    }
  }
}
.grayTableBody {
  padding: 1rem;
  background-color: white;
  color: black;
  font-weight: bold;
  display: flex;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  .grayTableItem {
    width: calc(100% / 6);
    &:first-child {
      width: calc(100% / 7);
    }
    &:last-child {
      width: calc(100% / 5);
    }
  }
}

// STATES
.stateBase {
  font-weight: 500;
  font-style: italic;
  font-size: 12px;
  padding: 3px 6px 4px;
  border-radius: 5px;
  margin-bottom: 6px;
}
.activeState {
  width: 44px;
  background-color: #11b78c;
}
.inProcessState {
  width: 52px;
  background-color: #4897d9;
}
.paidState {
  width: 52px;
  background-color: #11b78c;
}

// MARGIN
.mtb-4 {
  margin: 32px 0;
}
.mt-4 {
  margin-top: 32px;
}
// MODAL ADD EMPLOYEE
.twoElements {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  .white_button,
  .blueButton {
    width: 48%;
  }
}
.white_button {
  font-family: Poppins;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--primary-1);
  padding: 0.5rem 1rem;
  border-color: white;
  width: 210px;
  border-radius: 5px;
  box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
    -5px -5px 8px 0 var(--b-w-6);
  background-color: var(--b-w-6);
  border-width: 0;
  height: 39px;
}
.fwbold {
  font-weight: bold !important;
}
.blueButton {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
    -5px -5px 8px 0 var(--b-w-6);
  border: solid 1px #048aa8;
  background-color: #048aa8;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  width: 210px;
  height: 39px;
}
.gray_button {
  background-color: #fafafa;
  padding: 8px 24px;
  border-radius: 5px;
  box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
    -5px -5px 8px 0 var(--b-w-6);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  color: #777274;
  border: 1px solid #fafafa;
  text-transform: uppercase;
}
.white_button_bluecolor {
  padding: 8px 24px;
  border-radius: 5px;
  box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
    -5px -5px 8px 0 var(--b-w-6);
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  color: #11b78c;
  border-color: transparent;
  text-transform: uppercase;
}
.modal_white_box {
  margin-top: 0 !important;
}
.modal-box {
  .mediumText {
    text-align: left;
  }
  .navtabstable {
    margin: 0 0 1rem 0;
    padding: 0;
  }
  .twocolumns {
    width: 100%;
    display: flex;
    margin-top: 1rem;
    .element {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .blackText {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: italic;
        margin: 0;
      }
    }
    .inputBase {
      width: 90%;
      margin-top: 1rem;
    }
    .checkText {
      display: flex;
      .blackText {
        margin-left: -4rem;
      }
    }
    .blueDarkColorT {
      color: #1d3557;
    }
    //COLOR FOR TIPO DE AHORRO
    .blueMediumColorT {
      color: #048aa8;
    }
    .blueLightColorT {
      color: #6cb6f5;
    }
    .pinkColorT {
      color: #ef476f;
    }
    .orangeColorT {
      color: #fa7e58;
    }
    .yellowColorT {
      color: #f5a61d;
    }
    .greenColorT {
      color: #15db93;
    }
  }
  .inputBase {
    border: 1px solid #ececec;
    border-radius: 5px;
    padding: 6px;
    margin-top: 1rem;
  }
}
button {
  cursor: pointer;
}
.title_BI {
  padding: 0 0 1rem 0;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  margin-bottom: 1rem;
  .whiteModalTitle {
    margin: 0 !important;
  }
  .switchAndIcon {
    margin-right: 0;
    margin-left: auto;
  }
}
.whiteModalTitle {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin-top: 0;
  text-align: left;
  margin-bottom: 1rem;
}
.mbbasex4 {
  margin-bottom: 2rem !important;
}

// Menu tab
.navtabstable {
  display: flex;
  padding: 0 1rem;
  margin-top: 1.5rem;
  color: #1d3557;
  margin-bottom: 1.5rem;
  .navtab {
    padding: 0.5rem;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    cursor: pointer;
    opacity: 0.5;
  }
  .navtabselected {
    border-bottom: 3px solid #1d3557;
    opacity: 1;
  }
  .navtabsunelected {
    border-bottom: 1px solid transparent;
  }
}
.mtbase {
  margin: 1rem !important;
}
.mt_b {
  margin-top: 1rem;
}

// Circle
.circle_and_text {
  display: flex;
  align-items: center;
}
.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 8px;
}
.blueDarkColor {
  background-color: #1d3557;
}
.blueMediumColor {
  background-color: #048aa8;
}
.blueLightColor {
  background-color: #6cb6f5;
}
.pinkColor {
  background-color: #ef476f;
}
.orangeColor {
  background-color: #fa7e58;
}
.yellowColor {
  background-color: #f5a61d;
}
.greenColor {
  background-color: #15db93;
}
.fw500 {
  font-weight: 500 !important;
}
.text32 {
  font-size: 32px !important;
}
.grayLine {
  width: 100%;
  height: 1px;
  margin: 1.2rem 8px 1rem 0;
  background-color: #ececec;
  min-height: 1px;
}
.bigGrayLine {
  height: 2px !important;
}
.mtb {
  margin: 1rem 0 !important;
}
.nmargin {
  margin: 0 !important;
}
.smallerText {
  font-size: 14px !important;
}

// CANCEL MODAL
.backButtonModal {
  width: 20px;
  height: 19px;
  padding: 6px 4px 6px 11px;
  border-radius: 5px;
  box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5), -5px -5px 8px 0 #ffffff;
  background-color: rgba(166, 171, 189, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .backIcon {
    font-size: 14px;
    color: #777274;
  }
}
.modal-box {
  .twoColumns {
    display: flex;
    margin: 1.5rem 0;
    .element {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .cancellData {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    .element {
      width: calc(100% / 3);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1.5rem;
    }
  }
  .twoBig {
    .element {
      &:first-child,
      &:nth-child(2) {
        width: 50% !important;
      }
    }
  }
  .tasaCancelacion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1.5rem;
    .inputBase {
      width: 250px;
    }
  }
  .acceptButton {
    margin-top: 1.5rem;
    .white_opacityButton {
      opacity: 0.3;
      border-radius: 5px;
      box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
        -5px -5px 8px 0 #ffffff;
      background-color: #ffffff;
      font-size: 14px;
      color: #048aa8;
      text-transform: uppercase;
      font-family: "Poppins";
      font-weight: 500;
      border: 0;
      height: 34px;
      padding: 0 1rem;
    }
  }
}

.toBeApproved {
  padding: 8px;
  display: table;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  padding: 3px 6px 4px;
  border-radius: 5px;
  background-color: #ffc300;
  width: 55px;
  margin-top: 12px;
}
.approved {
  padding: 8px;
  display: table;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  padding: 3px 6px 4px;
  border-radius: 5px;
  background-color: #15db93;
  margin-top: 12px;
}
.rejected {
  padding: 8px;
  display: table;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  padding: 3px 6px 4px;
  border-radius: 5px;
  background-color: #d9484e;
  width: 55px;
  margin-top: 12px;
}
.paid {
  padding: 8px;
  display: table;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  padding: 3px 6px 4px;
  border-radius: 5px;
  background-color: #4897d9;
  width: 55px;
  margin-top: 12px;
}

.PaymentNoRegister {
  p {
    font-weight: bold;
    font-size: 32px;
    font-style: italic;
    color: #888888;
    text-align: center;
    margin: 3rem 0;
  }
}

//modal styles
div.modal-box {
  background-color: transparent !important;
  margin: 0 !important;
}
div.modal-box.modal_white_box {
  background-color: transparent !important;
  margin: 0 !important;
}
.toggle__container {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.toggle__main {
  right: 1px;
  width: 50%;
  height: 80%;
  background-color: #fff;
  //border: 1px solid rgba(92, 92, 92, 0.2);
  padding: 8px;
  z-index: 150;
  border-radius: 5px;
  box-shadow: 2px 2px 18px rgba(218, 218, 218, 0.5);
  animation: moveUp 1s;
}

.container {
  padding: 0 10px;
}

.toggle__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  height: 60px;
}

.toggle__footer {
  display: flex;
  justify-content: flex-start;
}

.toggle__footer {
  .btn-primary-custom {
    width: 120px;
    background-color: #fd9c00;
    border: none;
    color: #fff;
    margin-right: 10px;
    &:hover {
      background-color: #bd7503;
      color: #fff;
    }
  }

  .custom-btn-primary span {
    padding-right: 10px;
  }
}

@media (max-width: 768px) {
  .toggle__main {
    position: fixed;
    right: 1px;
    width: 60%;
  }
}

@media (max-width: 480px) {
  .toggle__main {
    position: absolute;
    right: 1px;
    width: 100%;
    margin-top: -34px;
  }
}

@media only screen and (max-width: 375px) {
  .btn-primary-custom {
    font-size: 14px;
  }
}

//Confuguracion -> empresa -> ahorros

span.contentTitle {
  color: #fff;
}
span.MuiTypography-root.MuiFormControlLabel-label.Mui-disabled.MuiTypography-body1 {
  color: #777274;
}

.error_notification {
  color: red;
}

.arrowIcons {
  transform: rotateY(180deg);
}

.text-select {
  color: #777274;
  font-size: 24px;
}
