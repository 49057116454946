.footer-landing {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #1d3557;
  height: 80px;
  margin-top: -16px;
  color: #fafafa;

  &__container {
    width: 90%;
    display: flex;
    align-items: center;

    .logo {
      width: 15%;
    }

    .privacidad {
      width: 18%;
      text-align: center;
      margin: 10px;
      a {
        color: #fff;
        text-decoration: none;
        font-size: 14px;
      }
    }

    .social-media {
      width: 50%;
      display: flex;
      img {
        margin: 12px;
      }
    }

    .copy {
      width: 30%;
      font-size: 10px;
    }
  }
}
