.savings {
  * {
    font-family: Poppins;
  }
  display: flex;
  flex: 1;
  justify-content: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  //background-image: linear-gradient(to top, white 97%, white) !important;

  .savingHeroContent {
    width: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    background: url("../../../assets/images/employee/payrollAdvanceHero.svg")
        no-repeat left bottom,
      linear-gradient(to top, #048aa8 3%, #1d3557) !important;
    height: 470px;
  }
  .savingsContainer {
    display: flex;
    flex-direction: column;
    width: 65vw;
    padding: 4vh 8vh;

    .breadcrumbs {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding-right: 18px;
        padding-left: 18px;
        border-right: 2px solid;

        a {
          text-decoration: none;
          color: inherit;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .LoanHero {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: 600px;
    height: calc(60vh - 80px);
    justify-content: space-between;

    .content {
      padding: 4vh;
      padding-left: 0;

      .contentTitle {
        font-size: 48px;
        font-weight: bold;
        font-style: italic;
      }

      p {
        line-height: 200%;
        margin-bottom: 2rem;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        color: var(--b-w-5);
      }

      ul {
        padding: 0;
        list-style-position: inside;
        list-style-type: none;
        font-weight: 400;
      }

      li {
        padding-left: 1.3em;
      }

      li:before {
        //content: "-"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        color: var(--success-dark);
      }

      .text-style-1 {
        font-weight: 500;
        font-style: italic;
      }
    }

    .sideTableContainer {
      display: flex;
      justify-content: center;
      z-index: 1;
      .sideTableContent {
        width: 216px;
        max-width: 216px;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: self-start;
        text-align: center;
        background-color: white;
        border-radius: 15px;
        padding: 2rem;
        margin-top: 4rem;
        border: 1px solid #ececec;

        .sideItem {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 1rem;

          .key {
            font-family: Poppins;
            font-size: 12px;
            font-weight: 500;
            font-style: italic;
            color: var(--b-w-2) !important;
          }

          .value {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 500;
            font-style: italic;
            color: #11b78c;

            span {
              font-size: 15px;
            }
            .gray {
              font-size: 12px;
              color: #777274;
            }

            .money {
              font-size: 16px;
            }
          }
          .black {
            color: black;
            font-weight: 300;
          }
        }

        hr {
          width: 100%;
          border: solid 1px var(--b-w-4);
        }

        button {
          font-family: Poppins;
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 1px;
          padding: 0.5rem 1rem;
          border-color: white;
          width: 100%;
          border-radius: 5px;
          box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
            -5px -5px 8px 0 var(--b-w-6);
          background-color: white;
          color: #5dbcd2;
          border-width: 0;
        }
      }
    }
  }
  .loadHistoryContent {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .loadMaxHistory {
    width: calc(100% - 334px);
    h2 {
      font-size: 24px;
      font-weight: 300;
      font-style: italic;
      color: #122745;
    }
    .loadHistoryLine {
      width: 100%;
      height: 1px;
      background-color: #ececec;
    }
    .PaymentNoRegister {
      p {
        font-weight: bold;
        font-size: 32px;
        font-style: italic;
        color: #888888;
        text-align: center;
        margin: 3rem 0;
      }
    }
  }
  .PaymentRegister {
    width: 100%;
    padding: 1rem 1rem;
    border-radius: 20px;
    border: 1px solid #ececec;
    display: flex;
    margin: 2rem 0 4rem 0;
    .tableTitle {
      color: #777274;
      font-size: 12px;
      font-style: italic;
      font-weight: 500;
    }
    .status {
      width: 20%;
    }
    .amount {
      width: 30%;
    }
    .date {
      width: 30%;
      .amountLoad {
        color: black;
        span {
          font-size: 15px;
        }
      }
    }
    .statusLoad {
      width: 64px;
      height: 24px;
      background-color: #4897d9;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      font-size: 12px;
      font-style: italic;
      text-align: center;
      margin-top: 18px;
    }
    .toBeApproved {
      width: fit-content;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: italic;
      color: #ffffff;
      padding: 5px;
      margin-bottom: 5px;
      background-color: #ffc300;
    }
    .approved {
      width: fit-content;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: italic;
      color: #ffffff;
      padding: 5px;
      margin-bottom: 5px;
      background-color: #15db93;
    }
    .rejected {
      width: fit-content;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: italic;
      color: #ffffff;
      padding: 5px;
      margin-bottom: 5px;
      background-color: #d9484e;
    }
    .amountLoad {
      color: black;
      font-size: 30px;
      margin: 0;
    }
    .dateLoad {
      color: black;
      font-weight: 500;
      margin-top: 18px;
    }
  }
  .loadHistory {
    width: 65vw;
  }
}

.modal-box {
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: 60vh;
  overflow: auto;
  margin: 16px -32px -24px;
  padding: 16px 32px 24px;
  border-radius: 0px 0px 5px 5px;

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .data-focus {
      font-family: Poppins;
      font-size: 32px;
      font-weight: 500;
      font-style: italic;
      text-align: center;
      color: var(--b-w-1);
    }

    .sub-data {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      font-style: italic;
      text-align: center;
      color: var(--b-w-2);
    }
    .terms-data {
      width: 100%;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      text-align: left;
      color: var(--b-w-1);
    }
    .box-button {
      width: 100%;

      .green-button {
        margin: 31px 6px 0;
        padding: 8px 24px;
        border-radius: 5px;
        box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
          -5px -5px 8px 0 var(--b-w-6);
        background-color: var(--b-w-6);
      }

      .green-text {
        font-family: Poppins;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: var(--success-dark);
      }
    }
    .dot {
      height: 16px;
      width: 16px;
      margin: 16px 16px 0px 1px;
      border-radius: 50%;
      display: inline-block;
    }
  }

  .payroll-form-container {
    width: 100%;
    height: 100%;
  }

  .payroll-form {
    padding-top: 2%;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    &__header {
      width: 100%;
      display: flex;
    }

    &__left {
      width: 50%;
      display: flex;
      flex-direction: column;

      p {
        padding-top: 8%;
      }

      input {
        border: none;
        border-bottom: 2px solid gray;
        width: 250px;
      }
    }

    &__right {
      width: 50%;
      display: flex;
      justify-content: center;
    }

    &__circle {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      border: 15px solid #00a877;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      word-break: break-all;
      span {
        margin-top: -20px;
      }
    }

    &__circle-warning {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      border: 15px solid #cf0000;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      word-break: break-all;
      span {
        margin-top: -20px;
      }
      h2 {
        color: #cf0000;
      }
    }

    &__select-group {
      width: 80%;
      .plazo {
        padding-bottom: 10px;
      }
      .aval {
        padding-top: 25px;
        padding-bottom: 10px;
      }
    }

    &__calculate_loan {
      padding-top: 3%;
      width: 80%;
      display: flex;
      justify-content: flex-end;
    }
  }
  .dot-green {
    background-color: var(--success-light);
  }
  .form-content {
    display: flex;
    flex-direction: column;
  }
}
