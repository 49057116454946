.bossLoan {
  * {
    font-family: Poppins;
  }
  display: flex;
  flex: 1;
  justify-content: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: linear-gradient(to top, white 97%, white) !important;

  .companiesHeroContent {
    width: 100%;
    display: flex;
    justify-content: center;
    width: 100%;
    background: url("../../../assets/images/company/savingbackimg.svg")
        no-repeat left -70px top,
      linear-gradient(to top, #048aa8 3%, #048aa8) !important;
    height: 300px;
  }

  .companiesContainer {
    display: flex;
    flex-direction: column;
    width: 65vw;
    padding: 4vh 8vh;

    .breadcrumbs {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding-right: 18px;
        padding-left: 18px;
        border-right: 1px solid #fafafa;
        font-size: 14px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        color: #fafafa;

        a {
          text-decoration: none;
          color: inherit;
        }

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .companiesHero {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    min-height: 600px;
    height: calc(60vh - 80px);
    justify-content: space-between;

    .content {
      padding: 4vh;
      padding-left: 0;

      .contentTitle {
        font-size: 48px;
        font-weight: bold;
        font-style: italic;
      }

      p {
        line-height: 200%;
        margin-bottom: 2rem;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.86;
        letter-spacing: normal;
        color: var(--b-w-5);
      }

      ul {
        padding: 0;
        list-style-position: inside;
        list-style-type: none;
        font-weight: 400;
      }

      li {
        padding-left: 1.3em;
      }

      li:before {
        content: "-"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -1.3em; /* same as padding-left set on li */
        width: 1.3em; /* same as padding-left set on li */
        color: var(--success-dark);
      }

      .text-style-1 {
        font-weight: 500;
        font-style: italic;
      }
    }
  }

  .companiesContent {
    width: 100%;
    display: flex;
    justify-content: center;

    .companiesContentData {
      width: 70%;
      border-radius: 5px;
      margin-top: -5%;
      margin-bottom: 3rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .searchCompany {
    flex: 2;
    position: relative;
    border-radius: 5px;
    background-color: var(--b-w-6);
    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--b-w-1);
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5);
    margin-top: 1rem;
    width: 100%;
    &:hover {
      background-color: whitesmoke;
    }
  }

  .searchIconCompany {
    padding: 0 16px;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    color: var(--b-w-3);
    max-height: 800px;
    overflow: auto;
  }

  .white_button {
    font-family: Poppins;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--primary-1);
    padding: 0.5rem 1rem;
    border-color: white;
    width: 210px;
    border-radius: 5px;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -5px -5px 8px 0 var(--b-w-6);
    background-color: var(--b-w-6);
    border-width: 0;
    height: 39px;
  }

  .grayLine {
    width: 100%;
    height: 1px;
    margin: 1.2rem 8px 1rem 0;
    background-color: #ececec;
  }

  .grayLine2 {
    width: 100%;
    height: 1px;
    margin: 2rem;
    background-color: #ececec;
  }

  .history {
    font-family: Poppins;
    font-style: italic;
    font-weight: bold;
    font-size: 48px;
    line-height: 72px;
    color: #c5c5c5;
  }

  .bigLine {
    height: 2px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .listOfCompanies {
    margin-top: 1rem;
  }

  .principalContentCompany {
    background-color: white;
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
      -2px 1px 8px 0 var(--b-w-6);
    width: 100%;
    border-radius: 15px;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }

  .searchCompany {
    flex: 2;
    position: relative;
    border-radius: 5px;
    background-color: var(--b-w-6);
    width: 100%;
    padding-left: 0rem;
    padding-right: 4rem;
    display: flex;
    align-items: center;
    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--b-w-1);
    box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5);
    margin-top: 1rem;
    margin-bottom: 2rem;

    &:hover {
      background-color: whitesmoke;
    }

    .searchIconCompany {
      padding: 0 16px;
      height: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
      color: var(--b-w-3);
      max-height: 800px;
      overflow: auto;
    }
  }

  .savingsContent {
    width: 100%;

    .loans_title {
      font-family: Poppins;
      font-style: italic;
      font-weight: 300;
      font-size: 24px;
      line-height: 36px;
      color: #000;
      width: 40%;
    }

    .grayText {
      color: #777274;
      font-family: Poppins;
      display: flex;
      align-items: center;
      font-style: italic;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      margin: 0;
    }

    .tabHeader2 {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .tabItemC {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 25%;

        &.first {
          width: 15%;
        }

        &:last-child {
          width: 15%;
          align-self: center;
        }

        .blackText {
          color: #000;
          font-size: 20px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;

          .smallText {
            font-size: 16px;
            font-weight: 500;
            margin-right: 8px;
            margin-top: 6px;
          }

          .green-text {
            color: #11b78c;
          }

          .red-text {
            color: #c5274d;
          }
        }

        .blackTextDate {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;
        }
        .name {
          color: #000;
          font-family: Poppins;
          font-style: italic;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin: 0;
          display: flex;
          align-items: center;
        }

        .disable-text {
          color: var(--b-w-3);
        }

        .purple-content {
          width: fit-content;
          border-radius: 5px;
          background-color: #f3dbfc;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          color: #9146e8;
          padding: 8px;
          margin-bottom: 5px;
          float: right;
          margin-right: 10px;
        }

        .black-content {
          width: fit-content;
          border-radius: 5px;
          background-color: #000000;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          color: #ffffff;
          padding: 8px;
          display: table;
          margin: 0 auto;
          margin-bottom: 5px;
        }

        .green-content {
          width: fit-content;
          border-radius: 5px;
          background-color: #11b78c;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          color: #ffffff;
          padding: 8px;
          display: table;
          margin: 0 auto;
          margin-bottom: 5px;
        }

        .container-symbols {
          display: flex;
          justify-content: space-evenly;
        }

        .container-box {
          text-align: center;
        }

        .green-float {
          background-color: white;
          border: none;
          color: #11b78c;
          border-radius: 5px;
          transition-duration: 0.4s;
        }

        .green-float:hover {
          background-color: #8bffe0;
          color: #11b78c;
          box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
            -5px -5px 8px 0 var(--b-w-6);
        }

        .red-float {
          background-color: white;
          border: none;
          border-radius: 5px;
          color: #c5274d;
          transition-duration: 0.4s;
        }

        .red-float:hover {
          background-color: lightsalmon;
          color: #c5274d;
          box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
            -5px -5px 8px 0 var(--b-w-6);
        }
      }

      .arrow {
        /*position: absolute;
                width: 30px;
                height: 30px;
                color: black;*/
        width: 50px;
        height: 50px;
        margin: 20px auto 0px auto;
        border-radius: 10px;
        //box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5), -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
        color: black;
      }

      .icon-container {
        width: 60%;

        .purple-content {
          width: fit-content;
          border-radius: 5px;
          background-color: #f3dbfc;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: italic;
          color: #9146e8;
          padding: 8px;
          margin-bottom: 5px;
          float: right;
          margin-right: 10px;
        }

        .icon-purple {
          float: right;
        }
      }

      .tabItem {
        width: 12%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        img {
          width: 75px;
          height: 60px;
        }
        &:nth-last-child(2) {
          width: 20%;
        }
        &:last-child {
          width: 35%;
        }
        .companyName {
          color: #000;
          font-size: 30px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
        }
        .blackText {
          color: #000;
          font-size: 20px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;

          .smallText {
            font-size: 16px;
            font-weight: 500;
            margin-right: 8px;
            margin-top: 6px;
          }

          .blue-text {
            color: #1c96b2;
          }

          .green-text {
            color: #11b78c;
          }

          .red-text {
            color: #c5274d;
          }

          .greenText {
            color: #11b78c;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: italic;
            margin-left: 8px;
          }
        }
      }

      .tabItemB {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 30%;

        &:first-child {
          width: 20%;
        }

        &.half {
          width: 15%;
        }

        &:last-child {
          width: 10%;
        }

        .blackText {
          color: #000;
          font-size: 20px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;

          .smallText {
            font-size: 16px;
            font-weight: 500;
            margin-right: 8px;
            margin-top: 6px;
          }

          .green-text {
            color: #11b78c;
          }

          .red-text {
            color: #c5274d;
          }
        }

        .blackTextDate {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;
        }

        .disable-text {
          color: var(--b-w-3);
        }

        .purple-content {
          width: fit-content;
          border-radius: 5px;
          background-color: #f3dbfc;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          color: #9146e8;
          padding: 8px;
          margin-bottom: 5px;
          float: right;
          margin-right: 10px;
        }

        .black-content {
          width: fit-content;
          border-radius: 5px;
          background-color: #000000;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          color: #ffffff;
          padding: 8px;
          display: table;
          margin: 0 auto;
          margin-bottom: 5px;
        }

        .green-content {
          width: fit-content;
          border-radius: 5px;
          background-color: #11b78c;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          color: #ffffff;
          padding: 8px;
          display: table;
          margin: 0 auto;
          margin-bottom: 5px;
        }

        .container-symbols {
          display: flex;
          flex-direction: row-reverse;
          padding-bottom: 30px;
        }

        .container-box {
          text-align: center;
        }

        .green-float {
          background-color: white;
          border: none;
          color: #11b78c;
          position: absolute;
          margin-right: 45px;
          border-radius: 4px;
          transition-duration: 0.4s;
        }

        .green-float:hover {
          background-color: lightgreen;
          color: #11b78c;
          box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
            -5px -5px 8px 0 var(--b-w-6);
        }

        .red-float {
          background-color: white;
          border: none;
          color: #c5274d;
          border-radius: 4px;
          transition-duration: 0.4s;
        }

        .red-float:hover {
          background-color: lightsalmon;
          color: #c5274d;
          box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
            -5px -5px 8px 0 var(--b-w-6);
        }
      }
    }

    .tabHeader3 {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .loans_title {
        font-family: Poppins;
        font-style: italic;
        font-weight: 300;
        font-size: 24px;
        line-height: 36px;
        color: #000;
        width: 40%;
      }
      .icon-container {
        width: 60%;

        .purple-content {
          width: fit-content;
          border-radius: 5px;
          background-color: #f3dbfc;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: italic;
          color: #9146e8;
          padding: 8px;
          margin-bottom: 5px;
          float: right;
          margin-right: 10px;
        }

        .icon-purple {
          float: right;
        }
      }
    }

    .tabHeader {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .arrow {
        /*position: absolute;
                width: 30px;
                height: 30px;
                color: black;*/
        width: 50px;
        height: 50px;
        margin: 20px auto 0px auto;
        border-radius: 10px;
        //box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5), -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
        color: black;
      }

      .icon-container {
        width: 60%;

        .purple-content {
          width: fit-content;
          border-radius: 5px;
          background-color: #f3dbfc;
          font-size: 14px;
          font-weight: bold;
          font-stretch: normal;
          font-style: italic;
          color: #9146e8;
          padding: 8px;
          margin-bottom: 5px;
          float: right;
          margin-right: 10px;
        }

        .icon-purple {
          float: right;
        }
      }

      .tabItem {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        img {
          width: 75px;
          height: 60px;
        }
        &:nth-last-child(2) {
          width: 25%;
        }
        &:last-child {
          width: 35%;
        }
        .companyName {
          color: #000;
          font-size: 30px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
        }
        .blackText {
          color: #000;
          font-size: 20px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;

          .smallText {
            font-size: 16px;
            font-weight: 500;
            margin-right: 8px;
            margin-top: 6px;
          }

          .blue-text {
            color: #1c96b2;
          }

          .green-text {
            color: #11b78c;
          }

          .red-text {
            color: #c5274d;
          }

          .greenText {
            color: #11b78c;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: italic;
            margin-left: 8px;
          }
        }
      }

      .tabItemB {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;

        &:first-child {
          width: 20%;
        }

        &.half {
          width: 15%;
        }

        &:last-child {
          width: 15%;
          align-self: center;
        }

        .blackText {
          color: #000;
          font-size: 20px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;

          .smallText {
            font-size: 16px;
            font-weight: 500;
            margin-right: 8px;
            margin-top: 6px;
          }

          .green-text {
            color: #11b78c;
          }

          .red-text {
            color: #c5274d;
          }
        }

        .blackTextDate {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;
        }

        .name {
          color: #000;
          font-family: Poppins;
          font-style: italic;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin: 0;
          display: flex;
          align-items: center;
        }

        .disable-text {
          color: var(--b-w-3);
        }

        .purple-content {
          width: fit-content;
          border-radius: 5px;
          background-color: #f3dbfc;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          color: #9146e8;
          padding: 8px;
          margin-bottom: 5px;
          float: right;
          margin-right: 10px;
        }

        .black-content {
          width: fit-content;
          border-radius: 5px;
          background-color: #000000;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          color: #ffffff;
          padding: 8px;
          display: table;
          margin: 0 auto;
          margin-bottom: 5px;
        }

        .green-content {
          width: fit-content;
          border-radius: 5px;
          background-color: #11b78c;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: italic;
          color: #ffffff;
          padding: 8px;
          display: table;
          margin: 0 auto;
          margin-bottom: 5px;
        }

        .container-symbols {
          display: flex;
          justify-content: space-evenly;
        }

        .container-box {
          text-align: center;
        }

        .green-float {
          background-color: white;
          border: none;
          color: #11b78c;
          border-radius: 5px;
          transition-duration: 0.4s;
        }

        .green-float:hover {
          background-color: #8bffe0;
          color: #11b78c;
          box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
            -5px -5px 8px 0 var(--b-w-6);
        }

        .red-float {
          background-color: white;
          border: none;
          border-radius: 5px;
          color: #c5274d;
          transition-duration: 0.4s;
        }

        .red-float:hover {
          background-color: lightsalmon;
          color: #c5274d;
          box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
            -5px -5px 8px 0 var(--b-w-6);
        }
      }
    }

    .loadHistoryPayments {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 2rem 0;
    }

    .PaymentNoRegister {
      p {
        font-weight: bold;
        font-size: 32px;
        font-style: italic;
        color: #888888;
        text-align: center;
        margin: 3rem 0;
      }
    }

    .loadHistoryPaymentsHeader {
      padding: 1rem;
      background-color: #ececec;
      color: #777274;
      font-weight: bold;
      text-align: center;
      font-size: 12px;
      font-style: italic;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    .loadHistoryPaymentsTitles {
      padding: 1rem;
      background-color: #fafafa;
      color: #777274;
      font-weight: 500;
      display: flex;
      text-align: center;
      font-size: 12px;
      font-style: italic;

      .loadHistoryPaymentsTitle {
        width: calc(100% / 6);

        &:first-child {
          width: calc(100% / 7);
        }

        &:last-child {
          width: calc(100% / 5);
        }
      }
    }

    .loadHistoryPaymentsBody {
      padding: 1rem;
      background-color: white;
      color: black;
      font-weight: bold;
      display: flex;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      font-style: italic;

      .loadHistoryPaymentsItem {
        width: calc(100% / 6);
        &:first-child {
          width: calc(100% / 7);
        }
        &:last-child {
          width: calc(100% / 5);
        }
      }

      .greenText {
        color: #11b78c;
      }
    }
  }

  .totalComapniesTitle {
    width: 100%;
    color: #000;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: italic;
    margin: 0;
  }

  .companiesHeader {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    .item {
      width: 14%;
      &:first-child {
        width: 30%;
      }
      &:nth-child(4) {
        width: 20%;
      }
    }
    .grayDarkText {
      color: #777274;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      span {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
      }
      .greenText {
        color: #11b78c;
      }
    }
  }

  .companyDescription {
    width: 100%;
    border-radius: 5px;
    box-shadow: 6px 6px 16px 0 rgba(166, 171, 189, 0.5);
    background-color: #fff;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    cursor: pointer;

    .paddingContent {
      padding: 1.5rem;
      width: 100%;
      display: flex;

      .imageText {
        img {
          width: 70px;
          height: 65px;
        }

        p {
          margin: 0 0 0 1rem;
        }
      }

      .item {
        width: 14%;
        display: flex;
        align-items: center;

        &:first-child {
          width: 30%;
        }

        &:nth-child(4) {
          width: 20%;
        }

        .itemCompanyName {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
        }

        .blackText {
          color: #000;
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: italic;
          margin: 0;
          display: flex;
          align-items: center;

          .smallText {
            font-size: 16px;
            font-weight: 500;
            margin-right: 8px;
            margin-top: 6px;
          }

          .greenText {
            color: #11b78c;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: italic;
            margin-left: 8px;
          }
        }
      }

      .arrowIcon {
        display: flex;
        align-items: center;
        color: #000;
        margin-left: 2rem;
      }
    }
  }

  .itemUnSelected {
    opacity: 0.6;
  }

  .companySelected {
    opacity: 1;
  }

  .companyUnSelected {
    background-color: rgba(166, 171, 189, 0.3);
  }

  .whiteShadowBox {
    padding: 16px;
    border-radius: 5px;
    box-shadow: 6px 6px 16px 0 rgba(166, 171, 189, 0.5);
    background-color: white;
    margin-top: 1rem;

    .bigSubtitle {
      margin: 0;
      font-size: 24px;
      font-weight: 300;
      font-stretch: normal;
      font-style: italic;
      color: #000;
    }
  }

  .toBeApproved {
    width: fit-content;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    color: #ffffff;
    padding: 8px;
    display: table;
    margin-bottom: 5px;
    background-color: #ffc300;
  }
  .dispersado {
    width: fit-content;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    color: #ffffff;
    padding: 8px;
    display: table;
    margin-bottom: 5px;
    background-color: #ffc300;
  }
  .approved {
    width: fit-content;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    color: #ffffff;
    padding: 8px;
    display: table;
    margin-bottom: 5px;
    background-color: #15db93;
  }
  .autorizado {
    width: fit-content;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    color: #ffffff;
    padding: 8px;
    display: table;
    margin-bottom: 5px;
    background-color: #15db93;
  }
  .rejected {
    width: fit-content;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    color: #ffffff;
    padding: 8px;
    display: table;
    margin-bottom: 5px;
    background-color: #d9484e;
  }
  .paid {
    width: fit-content;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    color: #ffffff;
    padding: 8px;
    display: table;
    margin-bottom: 5px;
    background-color: #4897d9;
  }
}

.normal_button {
  font-family: Poppins;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--b-w-2);
  padding: 0.5rem 1rem;
  margin: 10px;
  border-color: white;
  width: 160px;
  border-radius: 5px;
  box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
    -5px -5px 8px 0 var(--b-w-6);
  background-color: var(--b-w-6);
  border-width: 0;
  height: 39px;
}

.rejected_button {
  font-family: Poppins;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--danger-light);
  padding: 0.5rem 1rem;
  margin: 10px;
  border-color: white;
  width: 160px;
  border-radius: 5px;
  box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
    -5px -5px 8px 0 var(--b-w-6);
  background-color: var(--b-w-6);
  border-width: 0;
  height: 39px;
}

.approved_button {
  font-family: Poppins;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--success-light);
  padding: 0.5rem 1rem;
  margin: 10px;
  border-color: white;
  width: 160px;
  border-radius: 5px;
  box-shadow: 5px 5px 8px 0 rgba(166, 171, 189, 0.5),
    -5px -5px 8px 0 var(--b-w-6);
  background-color: var(--b-w-6);
  border-width: 0;
  height: 39px;
}

.grayTextModal {
  color: #777274;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: italic;
  text-align: left;
  margin-top: 10px;
}

.modalSubtitle {
  font-family: "Poppins";
  color: var(--primary-2);
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  margin-top: 150px;
  margin-bottom: 5px;
}

// CANCEL MODAL
.modal-box {
  * {
    font-family: "Poppins";
  }
  .whiteBoxElementContent {
    border-radius: 10px;
    box-shadow: 10px 10px 10px 0 rgba(166, 171, 189, 0.5),
      -10px -10px 10px 0 rgba(255, 255, 255, 0.5);
    background-color: #ffffff;
    cursor: pointer;
    .inlineBoxElements {
      display: flex;
      padding: 16px;
      text-align: start;
      .boxElement {
        &:first-child {
          width: 5%;
          display: flex;
          justify-content: flex-end;
          align-items: left;
        }
        &:last-child {
          width: 5%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        .moreInfoIcon {
          font-size: 14px;
          color: #000000;
        }
      }
    }
  }
}
